import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const StageEditor = ({ selectedStage, subject, description, handleSubjectChange, handleDescriptionChange, handleSave }) => {
    return (
        <div className="border rounded-3 p-3">
            {selectedStage ? (
                <>
                    <div className="row align-items-center w-100">
                        <div className="col-auto">
                            <label htmlFor="inputSubject" className="col-form-label">Subject</label>
                        </div>
                        <div className="col">
                            <input
                                id="inputSubject"
                                type="text"
                                className="form-control w-100"
                                value={subject}
                                onChange={handleSubjectChange}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="w-100 mb-3">
                        <ReactQuill
                            theme="snow"
                            value={description}
                            placeholder="Enter your message here..."
                            onChange={handleDescriptionChange}
                            className="w-100"
                            style={{ height: "300px" }}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, 3, false] }],
                                    ["bold", "italic", "underline", "strike"],
                                    [
                                        {
                                            color: [
                                                "#000000", "#e60000", "#ff9900", "#ffff00",
                                                "#008a00", "#0066cc", "#9933ff", "#ffffff",
                                                "#facccc", "#ffebcc", "#ffffcc", "#cce8cc",
                                                "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666",
                                                "#ffc266", "#ffff66", "#66b966", "#66a3e0",
                                                "#c285ff", "#888888", "#a10000", "#b26b00",
                                                "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                                "#444444", "#5c0000", "#663d00", "#666600",
                                                "#003700", "#002966", "#3d1466",
                                            ],
                                        },
                                        {
                                            background: [
                                                "#000000", "#e60000", "#ff9900", "#ffff00",
                                                "#008a00", "#0066cc", "#9933ff", "#ffffff",
                                                "#facccc", "#ffebcc", "#ffffcc", "#cce8cc",
                                                "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666",
                                                "#ffc266", "#ffff66", "#66b966", "#66a3e0",
                                                "#c285ff", "#888888", "#a10000", "#b26b00",
                                                "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                                "#444444", "#5c0000", "#663d00", "#666600",
                                                "#003700", "#002966", "#3d1466",
                                            ],
                                        },
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link", "image"],
                                    ["clean"],
                                ],
                            }}
                            formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "list",
                                "bullet",
                                "link",
                                "image",
                                "color",
                                "background",
                            ]}
                        />
                    </div>
                    <div className="col-auto " style={{ marginTop: "70px" }}>
                        <button type="button" className="btn btn-primary mb-3" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </>
            ) : (
                <p>Select a stage to edit.</p>
            )}
        </div>
    );
};

export default StageEditor;