import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CampaignDataSave } from '../../services/authFunctions';


const CampaignForm = ({ onEmailsGenerated }) => {
  const [campaignData, setCampaignData] = useState({
    company_name: "",
    pain_points: "",
    value_proposition: "",
    call_to_action: "",
    additional_context: "",
    target_audience: "",
    tone: "professional",
  });
  
  const [sequences, setSequences] = useState([{ channel: "", delay: "" }]);
  const [generatedEmails, setGeneratedEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddSequence = () => {
    setSequences([...sequences, { channel: "", delay: "" }]);
  };

  const handleRemoveSequence = (index) => {
    if (sequences.length > 1) {
      const updatedSequences = sequences.filter((_, i) => i !== index);
      setSequences(updatedSequences);
    } else {
      alert("You must have at least one sequence.");
    }
  };

  const handleChange = (index, field, value) => {
    const updatedSequences = [...sequences];
    updatedSequences[index][field] = value;
    setSequences(updatedSequences);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validation
    const isValid = sequences.every(
      (sequence) => sequence.channel && sequence.delay
    );
    
    if (!isValid) {
      alert("Please fill out all sequence fields.");
      return;
    }

    // Ensure all campaign data is valid
    const requiredFields = [
      'company_name', 
      'pain_points', 
      'value_proposition', 
      'call_to_action'
    ];
    
    const missingFields = requiredFields.filter(field => !campaignData[field]);
    
    if (missingFields.length > 0) {
      alert(`Please fill out the following fields: ${missingFields.join(', ')}`);
      return;
    }

    setLoading(true);
    const generatedEmailsTemp = [];

    try {
      // Generate emails for each sequence with delay as content length
      for (let i = 0; i < sequences.length; i++) {
        const payload = {
          companyOrProductName: campaignData.company_name,
          customerPainPoints: campaignData.pain_points,
          valueProposition: campaignData.value_proposition,
          companyOverview: campaignData.additional_context || "",
          subjectLine: `${campaignData.company_name} - Sequence ${i + 1}`,
          targetAudience: campaignData.target_audience || "Potential Customers",
          tone: campaignData.tone,
          callToAction: campaignData.call_to_action,
          keyContent: `Sequence ${i + 1} communication`,
          temperature: 0.7,
          maxTokens: parseInt(sequences[i].delay) || 300,
        };
        const API_URL = process.env.REACT_APP_URL;
        const response = await fetch(`${API_URL}/api/generate-openai-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        
        const result = await response.json();
        console.log(response.ok);
        if (response.ok) {
          generatedEmailsTemp.push({
            channel: sequences[i].channel,
            email: result.email,
          });
        } else {
          throw new Error(result.error || "Error generating email");
        }
      }

      setGeneratedEmails(generatedEmailsTemp);
      onEmailsGenerated(generatedEmailsTemp);
      
      // Save campaign data
      const campaignPayload = {
        ...campaignData,
        sequences,
        generatedEmails: generatedEmailsTemp,
        
      };
      
      const response = await CampaignDataSave(campaignPayload);
      
      if (response && response.id) {
        const campaignId = response.id;
        console.log(`Campaign created successfully with ID: ${campaignId}`);
        navigate(`/campaigns/${campaignId}`);
      } else {
        throw new Error("Campaign ID is missing in the response.");
      }

      // Save campaign data
      
    } catch (error) {
      console.error("Error generating emails:", error);
      alert("An error occurred while generating emails. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center bg-light">
      <div className="card shadow-lg w-100" style={{ maxWidth: "900px" }}>
        <div className="row g-0">
          {/* Left Section */}
          <div className="col-md-4 bg-primary text-white rounded-start p-4">
            <h2 className="h5 fw-bold">Let AI assist with your sequences</h2>
            <p className="mt-3">
              Use AI to generate a complete campaign with sequential contact
              points to engage target audiences at scale.
            </p>
          </div>

          {/* Right Section */}
          <div className="col-md-8 p-5">
            <h3 className="h6 fw-bold text-secondary mb-4">
              Add Your Company Information
            </h3>
            <form onSubmit={handleSubmit}>
              {/* Company Name */}
              <div className="mb-3">
                <label htmlFor="companyName" className="form-label">
                  Company Name
                </label>
                <input
                  type="text"
                  id="companyName"
                  className="form-control"
                  placeholder="e.g., Office Beacon"
                  value={campaignData.company_name}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      company_name: e.target.value,
                    })
                  }
                  required
                />
              </div>

              {/* Target Audience */}
              <div className="mb-3">
                <label htmlFor="targetAudience" className="form-label">
                  Target Audience
                </label>
                <input
                  type="text"
                  id="targetAudience"
                  className="form-control"
                  placeholder="e.g., Small Business Owners"
                  value={campaignData.target_audience}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      target_audience: e.target.value,
                    })
                  }
                />
              </div>

              {/* Customer Pain Points */}
              <div className="mb-3">
                <label htmlFor="painPoints" className="form-label">
                  Customer Pain Points
                </label>
                <textarea
                  id="painPoints"
                  className="form-control"
                  placeholder="e.g., Too much manual work finding prospects and creating personalized outreaches"
                  rows="3"
                  value={campaignData.pain_points}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      pain_points: e.target.value,
                    })
                  }
                  required
                ></textarea>
              </div>

              {/* Value Proposition */}
              <div className="mb-3">
                <label htmlFor="valueProposition" className="form-label">
                  Value Proposition
                </label>
                <textarea
                  id="valueProposition"
                  className="form-control"
                  placeholder="e.g., End-to-end sales funnel automation"
                  rows="3"
                  value={campaignData.value_proposition}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      value_proposition: e.target.value,
                    })
                  }
                  required
                ></textarea>
              </div>

              {/* Call-to-Action */}
              <div className="mb-3">
                <label htmlFor="callToAction" className="form-label">
                  Call-to-Action
                </label>
                <textarea
                  type="text"
                  id="callToAction"
                  className="form-control"
                  placeholder="e.g., Book a demo"
                  value={campaignData.call_to_action}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      call_to_action: e.target.value,
                    })
                  }
                  required
                ></textarea>
              </div>

              {/* Tone */}
              <div className="mb-3">
                <label htmlFor="tone" className="form-label">
                  Tone
                </label>
                <select
                  id="tone"
                  className="form-select"
                  value={campaignData.tone}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      tone: e.target.value,
                    })
                  }
                >
                  <option value="professional">Professional</option>
                  <option value="friendly">Friendly</option>
                  <option value="casual">Casual</option>
                </select>
              </div>

              {/* Additional Context */}
              <div className="mb-3">
                <label htmlFor="additionalContext" className="form-label">
                  Additional Context
                </label>
                <textarea
                  id="additionalContext"
                  className="form-control"
                  placeholder='e.g., "OB made it easy for me to test out different content to land the perfect email messages." – John Smith'
                  rows="3"
                  value={campaignData.additional_context || ""}
                  onChange={(e) =>
                    setCampaignData({
                      ...campaignData,
                      additional_context: e.target.value,
                    })
                  }
                ></textarea>
              </div>

              {/* Add Your Sequence */}
              <div className="mb-3">
                <label className="form-label">Add Your Sequence</label>
                {sequences.map((sequence, index) => (
                  <div key={index} className="d-flex gap-2 mb-2 align-items-center">
                    <select
                      className="form-select"
                      value={sequence.channel}
                      onChange={(e) =>
                        handleChange(index, "channel", e.target.value)
                      }
                      required
                    >
                      <option value="">Select Channel</option>
                      <option value="email">Email</option>
                      <option value="sms">SMS</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="twitter">X (Twitter)</option>
                    </select>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Content Length"
                      value={sequence.delay}
                      onChange={(e) =>
                        handleChange(index, "delay", e.target.value)
                      }
                      required
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => handleRemoveSequence(index)}
                      >
                        &times;
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-outline-secondary mt-2"
                  onClick={handleAddSequence}
                >
                  + Add Sequence
                </button>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? "Generating..." : "Generate Emails"}
              </button>
            </form>

            {/* Display the generated emails if available */}
            {generatedEmails.length > 0 && (
              <div className="mt-4">
                <h5>Generated Emails</h5>
                {generatedEmails.map((emailData, index) => (
                  <div key={index} className="card mb-3">
                    <div className="card-header">
                      Sequence {index + 1} - {emailData.channel}
                    </div>
                    <div className="card-body">
                      <p>{emailData.email}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignForm;