import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { stopAiReply, resumeAiReply } from '../../../api/api';

const CampaignSendEmailData = () => {
    const { campaignId } = useParams();
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [popupData, setPopupData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupEmail, setPopupEmail] = useState("");
    const [openSections, setOpenSections] = useState({});
    const [stoppingAi, setStoppingAi] = useState({});
    const [resumingAi, setResumingAi] = useState({});


    const handleToggle = (index) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    useEffect(() => {
        console.log("CONTACTS HERE", contacts);
    }, [contacts]);


    const api_url = process.env.REACT_APP_URL;

    const fetchCampaigns = async (campaignId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `${api_url}/api/campaign-details`,
                { campaign_id: campaignId },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            console.log("API Full Response for details page:", response.data);

            if (response.data.success) {
                const groupedEmails = groupEmailsByRecipient(response.data.details.reverse());
                setContacts(groupedEmails);
            } else {
                setError(response.data.error || 'Failed to fetch campaigns');
            }
        } catch (err) {
            setError('Failed to fetch campaigns');
            console.error('Error fetching campaigns:', err.response ? err.response.data : err.message);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        if (!campaignId) {
            setError('Campaign ID is missing');
            setLoading(false);
            return;
        }

        setLoading(true);
        fetchCampaigns(campaignId);
    }, [campaignId]);




    const groupEmailsByRecipient = (emails) => {
        const emailGroups = {};

        emails.forEach((email) => {
            const recipientEmail = email.email_logs.recipient_email;

            if (!emailGroups[recipientEmail]) {
                emailGroups[recipientEmail] = {
                    primaryEmail: email.email_logs,
                    allEmails: [],
                    latestReply: null,
                     recipient_email: recipientEmail,
                     isAiStopped: false, // default to false
                };
            }

            emailGroups[recipientEmail].allEmails.push(email);

            // Update the latest reply
            if (
                email.processed_emails &&
                (!emailGroups[recipientEmail].latestReply ||
                    new Date(email.processed_emails.created_at) >
                    new Date(emailGroups[recipientEmail].latestReply.created_at))
            ) {
                emailGroups[recipientEmail].latestReply = email.processed_emails;
            }
        });

         const sortedGroups = Object.values(emailGroups).sort((a, b) => {
             const aHasReply = !!a.latestReply;
             const bHasReply = !!b.latestReply;

              if (aHasReply && !bHasReply) {
                  return -1; // a has a reply, b does not, so a comes first
             }
             if (!aHasReply && bHasReply) {
                 return 1; // b has a reply, a does not, so b comes first
             }

             const dateA = a.latestReply?.created_at || a.primaryEmail.created_at;
             const dateB = b.latestReply?.created_at || b.primaryEmail.created_at;
             return new Date(dateB) - new Date(dateA);

         });

        return sortedGroups;
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleDateString('en-US');
    };

    const formatTime = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };


    const openPopup = (emailGroup) => {
        setPopupData(emailGroup);
        setIsPopupOpen(true);
        setPopupEmail(emailGroup.primaryEmail.recipient_email)
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-danger">Error: {error}</div>;



    const renderPopupContent = () => {
        if (!popupData) return null;

        console.log("Popup Data:", popupData);

        // Remove duplicate emails based on processed_emails.created_at
        const uniqueEmails = popupData.allEmails.filter((email, index, self) => {
            const createdAt = email.processed_emails?.created_at;
            return self.findIndex(e => e.processed_emails?.created_at === createdAt) === index;
        });

        console.log("Unique Emails:", uniqueEmails);

        // Check if there are any responses
        const hasResponses = uniqueEmails.some(email => email.processed_emails?.email_body || email.processed_emails?.response_sent);

        return (
            <table className="table mb-0">
                <thead>
                    <tr className="table-light">
                        {/* <th>Sr.No</th> */}
                        <th>Recipient</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Sentiment</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {hasResponses ? (
                        uniqueEmails.map((email, emailIndex) => {
                            console.log(`Rendering email at index ${emailIndex}:`, email);

                            if (!email.processed_emails) {
                                console.log(`Skipping email at index ${emailIndex} due to missing processed_emails`);
                                return null;
                            }

                            const { email_body, response_sent, created_at, sentiment } = email.processed_emails;

                            return (
                                <React.Fragment key={`${emailIndex}-${created_at}`}>
                                    {/* Row for Recipient Message */}
                                    {email_body && (
                                        <>
                                            <tr>
                                                {/* <td>{emailIndex + 1}</td> */}
                                                <td>{popupEmail}</td>
                                                <td>{formatDate(created_at)}</td>
                                                <td>{formatTime(created_at)}</td>
                                                <td>
                                                    <span className="badge bg-success">Received</span>
                                                </td>
                                                <td>{sentiment || 'N/A'}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-sm"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#recipientMessage${emailIndex}`}
                                                        aria-expanded={openSections[emailIndex] ? 'true' : 'false'}
                                                        aria-controls={`recipientMessage${emailIndex}`}
                                                        onClick={() => handleToggle(emailIndex)}
                                                    >
                                                        {/* <i className={bi ${openSections[emailIndex] ? 'bi-chevron-down' : 'bi-chevron-up'}}></i> */}
                                                        <i
                                                            className="arrow-icon"
                                                            style={{ fontSize: '16px', color: '#333' }}
                                                        >
                                                            {openSections[emailIndex] ? '↓' : '↑'}
                                                        </i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr className="collapse" id={`recipientMessage${emailIndex}`}>
                                                <td colSpan="7" className="bg-light">
                                                    <strong>Message:</strong> {email_body || 'N/A'}
                                                </td>
                                            </tr>
                                        </>
                                    )}

                                    {/* Row for AI Response */}
                                    {response_sent && (
                                        <>
                                            <tr>
                                                {/* <td></td> */}
                                                <td>AI Response</td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <span className="badge bg-primary">Sent</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <button
                                                        className="btn btn-sm"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#aiResponse${emailIndex}`}
                                                        aria-expanded={openSections[emailIndex + 'ai'] ? 'true' : 'false'}
                                                        aria-controls={`aiResponse${emailIndex}`}
                                                        onClick={() => handleToggle(emailIndex + 'ai')}
                                                    >
                                                        {/* <i className={bi ${openSections[emailIndex + 'ai'] ? 'bi-chevron-down' : 'bi-chevron-up'}}></i> */}
                                                        <i
                                                            className="arrow-icon"
                                                            style={{ fontSize: '16px', color: '#333' }}
                                                        >
                                                            {openSections[emailIndex] ? '↓' : '↑'}
                                                        </i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr className="collapse" id={`aiResponse${emailIndex}`}>
                                                <td colSpan="7" className="bg-light">
                                                    <strong>AI Response:</strong> {response_sent || 'N/A'}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center">
                                No response data found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    };

    const handleStopAiReply = async (recipientEmail, index) => {
        setStoppingAi(prevState => ({ ...prevState, [index]: true }));
        try {
            await stopAiReply(recipientEmail, campaignId);
            setContacts(prevContacts => {
                return prevContacts.map((contact, contactIndex) => {
                    if (contactIndex === index) {
                        return {
                            ...contact,
                            isAiStopped: true
                        };

                    }
                    return contact
                })
            })

            console.log('AI replies stopped for:', recipientEmail);
        } catch (error) {
            console.error('Failed to stop AI reply:', error);
            setError('Failed to stop AI replies');

        } finally {
            setStoppingAi(prevState => ({ ...prevState, [index]: false }));
        }
    };


    const handleResumeAiReply = async (recipientEmail, index) => {
          setResumingAi(prevState => ({ ...prevState, [index]: true }));
        try {
            await resumeAiReply(recipientEmail, campaignId);
             setContacts(prevContacts => {
                return prevContacts.map((contact, contactIndex) => {
                    if (contactIndex === index) {
                        return {
                            ...contact,
                            isAiStopped: false
                        };

                    }
                    return contact
                })
            })

            console.log('AI replies resumed for:', recipientEmail);
        } catch (error) {
            console.error('Failed to resume AI reply:', error);
            setError('Failed to resume AI replies');
        } finally {
            setResumingAi(prevState => ({ ...prevState, [index]: false }));
        }
    };



    return (
        <div className="container my-4">
            <h3 className="mb-4">Campaign Recipient Status Details</h3>

            <div className="row">
                <div className="col-md-2">
                    <div className="border rounded p-3 mb-3">
                        <h5 className="mb-3">Filters</h5>
                        <div className="mb-2">
                            <label className="form-label">Status</label>
                            <input type="text" className="form-control" placeholder="Enter status" />
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Gender</label>
                            <input type="text" className="form-control" placeholder="Enter gender" />
                        </div>
                        <button className="btn btn-primary btn-sm w-100">Apply Filters</button>
                    </div>
                </div>

                <div className="col-md-10">
                    <table className="table table-hover table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th>Sr.No</th>
                                <th>Recipient Email</th>
                                <th>Stage</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th>Sentiment</th>
                                <th className="text-center">View Details</th>
                                <th className="text-center">Stop AI</th>
                            </tr>
                        </thead>

                        <tbody>
                            {contacts.map((emailGroup, index) => {
                                console.log(`Rendering email group at index ${index}:`, emailGroup);
                                const latestReply = emailGroup.latestReply;
                                const isReceived = !!latestReply;
                                const primaryEmail = emailGroup.primaryEmail;
                                const isSent = primaryEmail.is_sent === 1; // Check if the email is sent
                                const emailBody = primaryEmail.body || 'N/A';
                                const isAiStopped = emailGroup.isAiStopped; // Get the AI stopped state


                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{primaryEmail.recipient_email}</td>
                                        <td>{primaryEmail.stage || 'N/A'}</td>

                                        {/* Conditional Date and Time */}
                                        <td>{isReceived ? formatDate(latestReply.created_at) : formatDate(primaryEmail.created_at)}</td>
                                        <td>{isReceived ? formatTime(latestReply.created_at) : formatTime(primaryEmail.created_at)}</td>

                                        {/* Status Field */}
                                        <td>
                                            <span className={`badge ${isReceived ? 'bg-success' : isSent ? 'bg-warning text-dark' : 'bg-secondary'}`}>
                                                {isReceived ? 'Received' : isSent ? 'Sent' : 'N/A'}
                                            </span>
                                        </td>

                                        {/* Recipient Message with Tooltip */}
                                        <td>
                                            {isReceived && latestReply?.email_body ? (
                                                <span
                                                    title={latestReply.email_body}
                                                    className="text-truncate d-inline-block"
                                                    style={{
                                                        maxWidth: '200px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {latestReply.email_body.split(' ').slice(0, 3).join(' ')}...
                                                </span>
                                            ) : (
                                                <span
                                                    title={emailBody}
                                                    className="text-truncate d-inline-block"
                                                    style={{
                                                        maxWidth: '200px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {emailBody.split(' ').slice(0, 3).join(' ')}...
                                                </span>
                                            )}
                                        </td>

                                        {/* Sentiment */}
                                        <td>
                                            {latestReply?.sentiment ? (
                                                <span
                                                    className={`badge bg-${latestReply.sentiment.trim().toLowerCase() === 'positive'
                                                        ? 'success'
                                                        : latestReply.sentiment.trim().toLowerCase() === 'negative'
                                                            ? 'warning text-dark'
                                                            : 'info'
                                                        }`}
                                                >
                                                    {latestReply.sentiment.trim()}
                                                </span>
                                            ) : (
                                                <span className="badge bg-secondary">-</span>
                                            )}
                                        </td>

                                        {/* Details Button */}
                                        <td className="text-center">
                                            <button
                                                onClick={() => openPopup(emailGroup)}
                                                className="expand-button badge bg-secondary cursor-pointer"
                                            >
                                                Show details
                                            </button>
                                        </td>
                                         {/* AI Stop/Resume Buttons */}
                                         <td className="text-center">
                                              {isAiStopped ? (
                                                    <button
                                                         className="btn btn-success btn-sm"
                                                         onClick={() => {
                                                             const recipientEmail = primaryEmail.recipient_email;
                                                             const confirmed = window.confirm(`Are you sure you want to resume sending responses for ${recipientEmail}?`);
                                                            if(confirmed) {
                                                               handleResumeAiReply(recipientEmail, index);
                                                            }
                                                        }}
                                                         disabled={resumingAi[index]}
                                                     >
                                                        {resumingAi[index] ? 'Resuming...' : 'Resume'}
                                                     </button>
                                                  ) : (
                                                      <button
                                                          className="btn btn-danger btn-sm"
                                                          onClick={() => {
                                                              const recipientEmail = primaryEmail.recipient_email;
                                                             const confirmed = window.confirm(
                                                                `Are you sure you want to stop sending responses for ${recipientEmail}?`
                                                            );
                                                           if (confirmed) {
                                                                handleStopAiReply(recipientEmail, index);
                                                             }
                                                          }}
                                                          disabled={stoppingAi[index]}
                                                      >
                                                          {stoppingAi[index] ? 'Stopping...' : 'STOP'}
                                                      </button>
                                                  )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>

                    </table>

                </div>
            </div>
            {/* Popup */}
            {isPopupOpen && (
                <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }} >
                    <div className="modal-dialog modal-dialog-centered modal-xl" >
                        <div className="modal-content w-100" style={{ width: '100%', important: true }}>
                            <div className="modal-header">
                                <h5 className="modal-title">{popupEmail}</h5>
                                <button type="button" className="btn-close" onClick={closePopup}  ></button>
                            </div>
                            <div className="modal-body " style={{ position: 'relative', maxHeight: '400px', overflow: 'auto' }} >
                                {renderPopupContent()}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CampaignSendEmailData;