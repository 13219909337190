import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { CampaignDataSave } from "../../services/authFunctions";
import CampaignForm from "./openai_email_content_generator";

const AI_Assisted = ({ closeModal }) => {
  const [campaignName, setCampaignName] = useState("");
  const [steps, setSteps] = useState([{ day: 1, subject: "", message: "" }]);
  const navigate = useNavigate();
  const [isCampaignNameSubmitted, setIsCampaignNameSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showFirstSection, setShowFirstSection] = useState(true);

  // Hide the first section after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFirstSection(false);
      setShowForm(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  // Handle subject/message change for each step
  // const handleInputChange = (index, field, value) => {
  //   const newSteps = [...steps];
  //   newSteps[index][field] = value; // Assign the content directly
  //   setSteps(newSteps);
  // };

  const handleInputChange = (index, field, value) => {
    const newSteps = [...steps];
    newSteps[index][field] = value;
    setSteps(newSteps);
  };

  // Add a new sequence
  const addSequence = () => {
    const lastDay = steps.length > 0 ? steps[steps.length - 1].day + 3 : 1;

    setSteps([
      ...steps,
      {
        day: lastDay,
        subject: "",
        message: "",
      },
    ]);
  };

  // Remove a sequence
  const removeSequence = (indexToRemove) => {
    setSteps(steps.filter((_, index) => index !== indexToRemove));
  };

  // Handle form submission
  const handleSaveSequence = async () => {
    if (!campaignName || steps.some((step) => !step.subject || !step.message)) {
      alert("Please fill in all the required fields.");
      return;
    }

    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + (steps.length - 1) * 3);

    // Calculate start_date and start_time for each stage, dynamically based on the campaign start date
    const stages = steps.map((step, index) => {
      const stageStartDate = new Date(currentDate);
      stageStartDate.setDate(currentDate.getDate() + index * 3);
      stageStartDate.setHours(
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds()
      );

      const start_date = stageStartDate.toISOString().split("T")[0];
      const start_time = stageStartDate
        .toISOString()
        .split("T")[1]
        .split(".")[0];

      return {
        stage_number: index + 1,
        subject: step.subject,
        description: step.message,
        start_date: start_date,
        start_time: start_time,
      };
    });

    const campaignData = {
      user_id: "1",
      name: campaignName,
      status: "scheduled",
      scheduled_at: currentDate.toISOString().split("T")[0],
      start_date: currentDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
      stages: stages,
    };

    try {
      console.log("Sending request to API..." + campaignData);
      const response = await CampaignDataSave(campaignData);
      console.log(response);
      if (response && response.id) {
        const campaignId = response.id;
        console.log(`Campaign created successfully with ID: ${campaignId}`);
        navigate(`/campaigns/${campaignId}`);
      } else {
        throw new Error("Campaign ID is missing in the response.");
      }

      // Close modal
      closeModal();
    } catch (error) {
      console.error("Error saving campaign:", error);
      alert("There was an error saving the campaign. Please try again.");
    }
  };

  return (
    <>
      <div className="modal-backdrop fade show" onClick={closeModal}></div>

      <div className="modal fade show d-block" id="ai-assisted" tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered h-75">
          <div className="modal-content h-100">
            {/* First Section: AI Working (Visible for 3 seconds) */}
            {showFirstSection && (
              <div className="modal-body h-100">
                <div className="d-flex w-100 h-100 align-items-center justify-content-center ff-inter">
                  <div className="bg-white rounded-3 text-center">
                    <img src="assets/img/ai-email-creating.png" alt="" />
                    <p className="fs-14 text-center pb-2">
                      OB AI is hard at work. <br />
                      Creating an email just for you...
                    </p>
                    <p className="fs-14 text-center">
                      <strong>Please note:</strong> it can take up to a minute
                      to generate a sequence.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Second Section: Campaign Name Input (Visible after 3 seconds) */}
            {!showFirstSection && !isCampaignNameSubmitted && (
              <>
                <button
                  type="button"
                  className="btn-close position-absolute end-0 top-0 mt-2 me-2 zindex-1"
                  onClick={closeModal}
                ></button>
                <div className="modal-body h-100">
                  <div className="w-75 m-auto d-flex h-100 align-items-center flex-column justify-content-center ff-inter">
                    <h5 className="fs-20">Let's create a new campaign</h5>
                    <span className="fs-14 tc-979797 pb-3 d-block">
                      What would you like to name it?
                    </span>
                    <input
                      type="text"
                      className="w-100 p-2"
                      placeholder="Campaign Name"
                      value={campaignName}
                      onChange={(e) => setCampaignName(e.target.value)}
                      required
                    />
                    <div className="text-center">
                      <button
                        className="btn border rounded-3 fs-14 w-200 p-2 my-2 me-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary rounded-3 fs-14 w-200 p-2 my-2"
                        onClick={() => {
                          if (!campaignName) {
                            alert("Campaign name is required.");
                            return;
                          }
                          setIsCampaignNameSubmitted(true);
                          setShowForm(true);
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Form for Editing Steps (Visible after campaign name is submitted) */}
            {isCampaignNameSubmitted && showForm && (
              <div className="modal-body h-100">
                <div className="bg-F5F8FF d-flex py-3 px-4 justify-content-between ff-inter">
                  <div>
                    <h6 className="fw-500">Finalize your content</h6>
                    <p className="mb-0 fs-14">
                      Review and edit your emails, then save your sequence.
                    </p>
                  </div>
                  <button
                    type="button"
                    className="btn fs-14 mt-2 me-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M12.9166 15.8335L7.79036 10.7073C7.39983 10.3167 7.39983 9.68358 7.79036 9.29306L12.9166 4.16683"
                        stroke="#747474"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Back
                  </button>
                </div>

                <div className="border-bottom mb-3 pb-3 d-flex justify-content-between pt-3 px-3">
                  <h5 className="fs-20">Outbound AI Sequence</h5>
                  <span className="fs-14 tc-979797">
                    {steps.length} steps | {steps.length * 3} days in sequence{" "}
                    <img
                      src="assets/img/svg/info-icon.svg"
                      width="20"
                      className="ms-2"
                      alt=""
                    />
                  </span>
                </div>

                <div className="accordion p-3" id="accordionExample">
                  {steps.map((step, index) => (
                    <div
                      className="accordion-item position-relative"
                      key={index}
                    >
                      {steps.length > 1 && (
                        <button
                          className="btn btn-danger btn-sm position-absolute top-0 end-0 m-2 z-10"
                          onClick={() => removeSequence(index)}
                        >
                          &times;
                        </button>
                      )}
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className="accordion-button bg-white rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded={index === 0 ? "true" : "false"}
                          aria-controls={`collapse${index}`}
                          data-bs-parent="#accordionExample"
                        >
                          <span className="fs-20 fw-500 pe-3">{index + 1}</span>
                          <span className="pe-3">
                            <img src="assets/img/email-icon.png" alt="" />
                          </span>
                          <div>
                            <p className="tc-979797 fs-12 mb-0">
                              Day {step.day} - Outreach
                            </p>
                            <p className="fs-14 mb-0 fw-700">Automatic email</p>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="w-100">
                            <div className="mb-2">
                              <label className="form-label fs-12">
                                Subject
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={step.subject}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "subject",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="mb-2">
                              <label className="form-label fs-12">
                                Message
                              </label>
                              {/* <textarea
                                className="form-control"
                                 rows="3"
                                 value={step.message}
                                 onChange={(e) => handleInputChange(index, "message", e.target.value)}
                                  ></textarea> */}
                              <ReactQuill
                                theme="snow"
                                value={step.message}
                                onChange={(content) =>
                                  handleInputChange(index, "message", content)
                                }
                                placeholder="Write message here..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Add Sequence Button */}
                <div className="text-center mb-3">
                  <button
                    className="btn btn-outline-primary rounded-circle"
                    onClick={addSequence}
                    title="Add Sequence"
                  >
                    +
                  </button>
                </div>

                <div className="text-center">
                  <button className="btn border rounded-3 fs-14 w-200 p-2 my-2 me-2">
                    Edit my information
                  </button>
                  <button
                    className="btn btn-primary rounded-3 fs-14 w-200 p-2 my-2"
                    onClick={handleSaveSequence}
                  >
                    Save Sequence
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AI_Assisted;
