import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CampaignData } from '../services/authFunctions';

// Initial state for the slice
const initialState = {
    campaigns: [],
    loading: false,
    error: null,
};

// Async thunk for fetching campaigns
export const fetchCampaigns = createAsyncThunk(
    'campaigns/fetchCampaigns',
    async () => {
        try {
            const campaigns = await CampaignData();
            if (Array.isArray(campaigns)) {
                return campaigns.reverse();
            } else {
                throw new Error("Expected an array, but received:" + campaigns);
            }
        } catch (error) {
            throw new Error(`Failed to fetch campaigns: ${error.message}`);
        }
    }
);

// Create the slice
const campaignSlice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaigns.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCampaigns.fulfilled, (state, action) => {
                state.loading = false;
                state.campaigns = action.payload;
            })
            .addCase(fetchCampaigns.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.campaigns = [];
            });
    },
});

// Selectors
export const selectAllCampaigns = (state) => state.campaigns.campaigns;
export const selectCampaignLoading = (state) => state.campaigns.loading;
export const selectCampaignError = (state) => state.campaigns.error;

export default campaignSlice.reducer;