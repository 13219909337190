// src/features/authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login, register } from '../services/authFunctions';

// Async thunk for login
export const loginUser = createAsyncThunk(
    'auth/login',
    async (loginData, { rejectWithValue }) => {
      try {
        const response = await login(loginData);
        if(response.success === false) {
          return rejectWithValue({ message: response.message || 'Login failed. Please try again.' });
        }
        return response; // Return the entire response
      } catch (error) {
        return rejectWithValue({ message: error.response?.data?.message || 'Login failed. Please try again.' });
      }
    }
  );
  

// Async thunk for registration
export const registerUser = createAsyncThunk(
  'auth/register',
  async (registerData, { rejectWithValue }) => {
      try {
        const response = await register(registerData);
        return response; // Return the entire response
      } catch (error) {
          return rejectWithValue({ message: error.response?.data?.message || 'Registration failed. Please try again.', errors: error.response?.data?.data });
      }
  }
);

// Async thunk for logout
export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (_, {dispatch}) => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.clear();
  }
)

// Define initial state
const initialState = {
  user: null,
  token: localStorage.getItem('token'),
  userName: localStorage.getItem('userName'),
  loading: false,
  error: null,
  message: null,
  errors: {},
};

// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
      setMessage: (state, action) => {
        state.message = action.payload;
      },
      clearError: (state) => {
          state.error = null;
          state.errors = {};
      },
      clearMessage: (state) => {
          state.message = null;
      },
      setErrors: (state, action) => {
        state.errors = action.payload
      },
      clearUser: (state) => {
          state.user = null;
          state.token = null;
          state.userName = null;
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.errors = {};
      })
      .addCase(loginUser.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;
          state.token = action.payload.token;
          state.userName = action.payload.name;
          localStorage.setItem('token', action.payload.token);
          localStorage.setItem('userName', action.payload.name);
          state.message = 'Login successful';
      })
      .addCase(loginUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload.message;
          state.message = action.payload.message;
      })
      .addCase(registerUser.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.message = null;
          state.errors = {};
      })
      .addCase(registerUser.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;
          state.token = action.payload.token;
          state.userName = action.payload.name;
          localStorage.setItem('token', action.payload.token);
          localStorage.setItem('userName', action.payload.name);
          state.message = 'Registration Successful!';
      })
      .addCase(registerUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload.message;
          state.message = action.payload.message;
          state.errors = action.payload.errors
      })
        .addCase(logoutUser.fulfilled, (state) => {
            state.user = null;
            state.token = null;
            state.userName = null;
            state.message = 'Logged out successfully';
      })
  },
});

export const { setMessage, clearError, clearMessage, setErrors, clearUser } = authSlice.actions;
export default authSlice.reducer;