import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CampaignData, CampaignDataSaveEdit } from '../../../services/authFunctions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DetailsTabSequences = () => {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const campaignsData = await CampaignData(campaignId);

                if (Array.isArray(campaignsData)) {
                    const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                    if (filteredCampaign) {
                        setCampaign(filteredCampaign);
                        if (filteredCampaign.stages && filteredCampaign.stages.length > 0) {
                            const firstStage = filteredCampaign.stages[0];
                            setSelectedStage(firstStage);
                            setSubject(firstStage.subject || '');
                            setDescription(firstStage.description || '');
                        }
                    } else {
                        setError('Campaign not found');
                    }
                } else {
                    console.error("Expected an array, but received:", campaignsData);
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaigns:", err);
                setError('Failed to fetch campaigns');
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchCampaigns();
        } else {
            setError('Campaign ID is missing');
            setLoading(false);
        }
    }, [campaignId]);

    const handleStageClick = (stage) => {
        setSelectedStage(stage);
        setSubject(stage.subject || '');
        setDescription(stage.description || '');
    };

    const handleSave = async () => {
        console.log('Save button clicked');
        if (selectedStage && selectedStage.id) {

             if (!subject.trim() || !description.trim()) {
                 showAlert(`Subject and Message are required for stage ${selectedStage.stage_number}.`, "warning");
                 return;
             }

            try {
                const updatedStage = {
                    ...selectedStage,
                    subject,
                    description
                };

                const updatedStages = campaign.stages.map(stage =>
                    stage.id === selectedStage.id ? updatedStage : stage
                );

                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    return date.toISOString().slice(0, 19).replace('T', ' ');
                };

                if (!campaign || !campaign.id) {
                    alert("Campaign ID is missing. Cannot update.");
                    return;
                }
                const requestData = {
                    user_id: "1",
                    id: campaign.id,
                    name: campaign.name,
                    status: campaign.status,
                    scheduled_at: campaign.scheduled_at,
                    start_date: formatDate(campaign.start_date),
                    end_date: formatDate(campaign.end_date),
                    stages: updatedStages
                };

                console.log("Request Data to be sent to API:", requestData);
                const response = await CampaignDataSaveEdit(requestData);
                console.log("API Response from CampaignDataSaveEdit:", response);

                if (response && response.id) {
                    showAlert("Stage updated successfully!", "success");
                    setCampaign(prevCampaign => ({
                        ...prevCampaign,
                        stages: updatedStages
                    }));
                } else {
                    console.error('Unexpected API response:', response);
                    showAlert("Unexpected error occurred while saving stage.", "danger");
                }
            } catch (err) {
                console.error("Error updating stage:", err);
                showAlert("Error updating stage", "danger");
            }
        } else {
            showAlert("No valid stage selected to update.", "danger");
        }
    };

   const showAlert = (message, type) => {
        const alertContainer = document.getElementById("alert-container");
        const alertDiv = document.createElement("div");
        alertDiv.classList.add("alert", `alert-${type}`, "alert-dismissible", "fade", "show", "fixed-top");
        alertDiv.setAttribute("role", "alert");
        alertDiv.innerHTML = `
            ${message}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        `;
        alertContainer.appendChild(alertDiv);
        setTimeout(() => {
            alertDiv.classList.remove("show");
            alertDiv.classList.add("fade");
            setTimeout(() => alertDiv.remove(), 500);
        }, 3000);
    };


    if (loading) {
        return <p>Loading campaign data...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const handleAddStage = () => {
        if (campaign && campaign.stages && campaign.stages.length > 0) {
              const lastStage = campaign.stages[campaign.stages.length - 1];
                if (!lastStage.subject || !lastStage.description) {
                   showAlert(`Please add subject and message to stage ${lastStage.stage_number} to create a new stage`, "warning")
                    return;
                }
        }
       const highestStageId = campaign ? campaign.stages.reduce((max, stage) => Math.max(max, stage.id), 0) : 0;
        const newStage = {
            id: highestStageId + 1,
            stage_number: (campaign?.stages?.length || 0) + 1,
            subject: '',
            description: ''
        };
            setCampaign(prevCampaign => ({
                ...prevCampaign,
                stages: prevCampaign ? [...prevCampaign.stages, newStage] : [newStage]
            }));
        setSelectedStage(newStage);
        setSubject('');
        setDescription('');
    };

    const handleDelete = async (stageId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this stage?");
        if (!confirmDelete) return;

        try {
            const updatedStages = campaign.stages.filter(stage => stage.id !== stageId);
            setCampaign(prevCampaign => ({
                ...prevCampaign,
                stages: updatedStages
            }));

            const formatDate = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().slice(0, 19).replace('T', ' ');
            };

             const requestData = {
                user_id: "1",
                id: campaign.id,
                name: campaign.name,
                status: campaign.status,
                scheduled_at: campaign.scheduled_at,
                start_date: formatDate(campaign.start_date),
                end_date: formatDate(campaign.end_date),
                stages: updatedStages
            };

            const response = await CampaignDataSaveEdit(requestData);
            console.log("API Response from CampaignDataSaveEdit:", response);

            if (response && response.id) {
                showAlert("Stage deleted successfully!", "success");
            } else {
                console.error('Unexpected API response:', response);
                showAlert("Unexpected error occurred while deleting stage.", "danger");
            }
        } catch (err) {
            console.error("Error deleting stage:", err);
            showAlert("Error deleting stage", "danger");
        }
    };

    return (
        <div className="ff-inter h-100">
            <div id="alert-container"></div>
            <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                    <div className="bg-F5F8FF p-3 rounded-3">
                        {campaign && campaign.stages && campaign.stages.length > 0 ? (
                            campaign.stages.map((stage) => (
                                <div key={stage.id} className="card mb-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="card-body d-flex justify-content-between align-items-center text-dark text-decoration-none"
                                        onClick={() => handleStageClick(stage)}
                                    >
                                        <span>
                                            Stage Number {stage.stage_number}
                                        </span>
                                        {/* <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(stage.id);
                                            }}
                                            className="btn btn-danger btn-sm"
                                            title="Delete Stage"
                                        >
                                            Delete
                                        </button> */}
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p>No stages available</p>
                        )}

                        <button
                            onClick={handleAddStage}
                            className="btn border mt-3 center-block w-100"
                        >
                            Add Stage
                        </button>
                    </div>
                </div>
                <div className="col-12 col-md-8 col-xl-9">
                    <div className="border rounded-3 p-3">
                        {selectedStage ? (
                            <>
                                <div className="row align-items-center w-100">
                                    <div className="col-auto">
                                        <label htmlFor="inputSubject" className="col-form-label">Subject</label>
                                    </div>
                                    <div className="col">
                                        <input
                                            id="inputSubject"
                                            type="text"
                                            className="form-control w-100"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="w-100 mb-3">
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        placeholder='Enter your message here...'
                                        onChange={setDescription}
                                        className="w-100"
                                        style={{ height: "300px" }}
                                        modules={{
                                            toolbar: [
                                                [{ header: [1, 2, 3, false] }],
                                                ["bold", "italic", "underline", "strike"],
                                                [{ list: "ordered" }, { list: "bullet" }],
                                                ["link", "image"],
                                                ["clean"]
                                            ],
                                        }}
                                        formats={[
                                            "header",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "list",
                                            "bullet",
                                            "link",
                                            "image",
                                        ]}
                                    />
                                </div>
                                <div className="col-auto " style={{ marginTop: "70px" }}>
                                    <button type="button" className="btn btn-primary mb-3" onClick={handleSave}>
                                        Save
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p>Select a stage to edit.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsTabSequences;