import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { gsuiteDataSave } from '../../services/authFunctions';

const EmailSetupModal = ({ onClose }) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null); // To handle errors

    // Handle input change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Basic Email Format Validation (Regex)
    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Handle save button click
    const handleSave = async () => {
        if (!email) {
            alert("Please enter an email address.");
            return;
        }

        if (!isValidEmail(email)) {
            setError("Please enter a valid email address.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const requestData = {
                email: email,
                status: 0,        // Set status to 0
                is_active: false  // Assuming 'false' for "is_active", set to true if you want it active
            };
            const response = await gsuiteDataSave(requestData);
            

            if (response && response) {
                // Optionally, handle any additional success actions here
                setEmail("");
                const openURLnewtab = `${window.API_URL}/api/gsuite/auth/${email}`;
                window.open(openURLnewtab, '_blank'); 
            } else {
                throw new Error("Campaign ID is missing in the response.");
            }

            onClose(); // Close the modal if save is successful
        } catch (err) {
            const errorMessage = err?.message || 'Failed to save email. Please try again.';
            setError(errorMessage);
        } finally {
            setLoading(false); // Stop the loading state
        }
    };

    return (
        <div className="w-100 bg-white ff-inter offcanvas offcanvas-end show" id="addNewAccount">
            <div className="w-100 py-2 py-md-4 px-3 border-bottom">
                <Link
                    className="h4 tc-111111 text-decoration-none backWindow"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M15.5 19L9.20711 12.7071C8.81658 12.3166 8.81658 11.6834 9.20711 11.2929L15.5 5"
                            stroke="#111111"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Back
                </Link>
            </div>

            <div className="justify-content-evenly py-2 py-md-5 mt-md-3 mt-lg-5" id="connectAccounts" style={{ display: "flex" }}>
                <div className="w-100 mxw-420">
                    <h4 className="fw-500 mb-3 mb-md-4">Done for you email setup</h4>
                    <div className="text-decoration-none shadow d-flex rounded-3 px-4 py-3 align-items-center w-100 mb-3 mb-md-4 addemailaccount">
                        <span className="border-end p-2 pe-3 me-3">
                            <img src="assets/img/svg/google.svg" width="48" alt="" />
                        </span>
                        <div className="w-auto">
                            <p className="tc-979797 fs-14 mb-0">Google</p>
                            <p className="tc-111111 h4">Gmail / G-suite</p>
                        </div>
                    </div>
                    <input
                        className="form-control form-control-lg fs-14"
                        type="text"
                        placeholder="Enter your Email address"
                        value={email}
                        onChange={handleEmailChange}
                        disabled={loading}  // Disable the input while loading
                    />
                    {error && <p className="text-danger mt-2">{error}</p>} {/* Display error message */}
                    <div className="text-center mt-3">
                        <button
                            type="button"
                            className="btn btn-primary border mx-3 fs-14 mw-110"
                            onClick={handleSave}
                            disabled={loading} // Disable the button when loading
                        >
                            {loading ? "Saving..." : "Save"} {/* Change text to 'Saving...' when loading */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailSetupModal;
