import React, { useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom"; // Import useParams to get campaignId from the URL
import SearchBar from "../../../common/SearchBar.js";
import ContactFilter from "../../ContactBooks/ContactFilter.js";
import ContactData from "../../ContactBooks/ContactData.js";
import {  fetchCampaignDetails, setFilters, setSelectedFilters, setSearchQuery } from '../../../features/detailsTabLeadsSlice'; // Assuming you have an API method to update stage
import CampaignButton from "./CampaignButton.js";
import CampaignDataImport from "./DataImport";
import { useDispatch, useSelector } from 'react-redux';
const DetailsTabLeadsComponent = () => {
    const { campaignId } = useParams(); // Get the campaignId from the URL params
     const dispatch = useDispatch();
     const { campaign, loading, error, filters, selectedFilters, searchQuery, emailIds  } = useSelector((state) => state.detailsTabLeads);
     const [downloadUrl, setDownloadUrl] = useState("")
    const handleFiltersChange = (newFilters) => {
           dispatch(setFilters(newFilters));
    };

    // Clear all filters (reset both filters and selectedFilters)
    const handleClearFilters = () => {
          dispatch(setFilters({}));
          dispatch(setSelectedFilters({}));
    };

    const handleSearchChange = (query) => {
          dispatch(setSearchQuery(query));
    };

    useEffect(() => {
         if (campaignId) {
            dispatch(fetchCampaignDetails(campaignId));
           const apiUrl = process.env.REACT_APP_URL;
            setDownloadUrl(`${apiUrl}/api/download-excel`)
        }
    }, [dispatch, campaignId]);

    // Handle import success - trigger re-fetch of campaign data
      const handleImportSuccess = () => {
        dispatch(fetchCampaignDetails(campaignId));
    };


    const handleDownloadClick = () => {
        window.open(downloadUrl, '_blank');
    };

      if (loading) return <p>Loading campaign data...</p>;
    if (error) return <p>Error: {error}</p>;


    return (
        <div className="ff-inter h-100">
            <div className="row h-100 g-0">
                <div className="col-lg-2 col-md-3 h-100 pe-0 border-end">
                    <div className="border-bottom p-3">
                        <label className="fw-700 w-100 pb-3">Search</label>
                        <SearchBar onSearchChange={handleSearchChange} />
                    </div>

                    <div className="w-100">
                        <div className="d-flex px-3 py-2 border-bottom justify-content-between">
                            <label className="fw-700">Filter</label>
                            <button
                                className="border-0 bg-transparent fs-14 tc-979797"
                                onClick={handleClearFilters}
                            >
                                x Clear All
                            </button>
                        </div>
                        <div className="p-3">
                            <ContactFilter
                                onFiltersChange={handleFiltersChange}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={(filters) => dispatch(setSelectedFilters(filters))}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-10 col-md-9">
                    <div className="d-flex py-3 align-items-center px-3">
                        <h3 className="ff-inter h5 w-auto d-inline-block me-auto mb-0">Leads</h3>
                        {/* Import Button */}
                        <button type="button" className="btn border me-3 fs-14" onClick={handleDownloadClick}><img src="assets/img/svg/download.svg" alt="" /> Download </button>
                        <CampaignDataImport onImportSuccess={handleImportSuccess} />
                    </div>

                    {/* Show loading or error messages */}
                    {loading && <p>Loading campaign data...</p>}
                    {error && <p>Error: {error}</p>}

                    <div className="w-100">
                        {/* Only render ContactData if campaignId is fetched and data is available */}
                        {campaignId && !loading && !error && campaign && (
                            <ContactData
                                filters={filters}
                                searchQuery={searchQuery}
                                campaign_id={campaignId}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DetailsTabLeads = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <DetailsTabLeadsComponent />
        </Suspense>
    );
};

export default DetailsTabLeads;