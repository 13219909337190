import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const api_url = process.env.REACT_APP_URL;

export const fetchCampaignDetails = createAsyncThunk(
    'campaignDetails/fetchCampaignDetails',
    async (campaignId, { getState }) => {
        const token = localStorage.getItem('token');
         console.log("fetchCampaignDetails Action Started for campaignId:", campaignId);
        try {
            const response = await axios.post(
                `${api_url}/api/campaign-details`,
                { campaign_id: campaignId },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            console.log("fetchCampaignDetails API Response:", response);
            if (response.data.success) {
                console.log("fetchCampaignDetails Action Success:", response.data.details);
                return response.data.details;
            } else {
                  console.error("fetchCampaignDetails API Error:", response.data.error);
                throw new Error(response.data.error || 'Failed to fetch campaigns');
            }
        } catch (err) {
           console.error("fetchCampaignDetails API Catch Error:", err);
           throw new Error(err.response ? err.response.data.error : err.message || 'Failed to fetch campaigns');

        }
    }
);

export const stopAiReplyAction = createAsyncThunk(
    'campaignDetails/stopAiReply',
   async ({ recipientEmail, campaignId, index }, { getState }) => {
          const token = localStorage.getItem('token');
             console.log("stopAiReplyAction Action Started:", { recipientEmail, campaignId, index });
          try {
            const response =  await axios.post(
                 `${api_url}/api/stopAIEmail`,
                 { recipient_email: recipientEmail,  action: 'stop' },
                  {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                  }
            );
               console.log("stopAiReplyAction API Response:", response);
              if (response.data.status === "success" || response.data.status === "info") {
                   console.log("stopAiReplyAction Action Success:", { recipientEmail, stopped: true, index });
                    return { recipientEmail, stopped: true, index };
                } else {
                    console.error("stopAiReplyAction API Error:", response.data.message);
                     throw new Error(response.data.message || 'Failed to stop AI reply');
                }

         }
            catch (err) {
                  console.error("stopAiReplyAction API Catch Error:", err);
               throw new Error(err.response ? err.response.data.error : err.message || 'Failed to stop AI reply');

            }
    }
);

export const resumeAiReplyAction = createAsyncThunk(
    'campaignDetails/resumeAiReply',
   async ({ recipientEmail, campaignId, index }, { getState }) => {
         const token = localStorage.getItem('token');
         console.log("resumeAiReplyAction Action Started:", { recipientEmail, campaignId, index });
         try {
            const response =  await axios.post(
                 `${api_url}/api/stopAIEmail`,
                 { recipient_email: recipientEmail,  action: 'resume' },
                  {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                  }
            );
              console.log("resumeAiReplyAction API Response:", response);
              if (response.data.status === "success" ) {
                  console.log("resumeAiReplyAction Action Success:", { recipientEmail, stopped: false, index });
                    return { recipientEmail, stopped: false, index };
                }
               else {
                     console.error("resumeAiReplyAction API Error:", response.data.message);
                     throw new Error(response.data.message || 'Failed to resume AI reply');
                }
         }
         catch (err) {
                console.error("resumeAiReplyAction API Catch Error:", err);
              throw new Error(err.response ? err.response.data.error : err.message || 'Failed to resume AI reply');

         }
    }
);

const initialState = {
    contacts: [],
    loading: false,
    error: null,
    popupData: null,
    isPopupOpen: false,
    popupEmail: "",
    stoppingAi: {},
    resumingAi: {},

};

const campaignDetailsSlice = createSlice({
    name: 'campaignDetails',
    initialState,
    reducers: {
         openPopup: (state, action) => {
            console.log("openPopup reducer payload:", action.payload);
           state.popupData = action.payload;
            state.isPopupOpen = true;
           state.popupEmail = action.payload.primaryEmail.recipient_email;
        },
        closePopup: (state) => {
            state.isPopupOpen = false;
            state.popupData = null; // Clear popup data on close
             state.popupEmail = "";
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaignDetails.pending, (state) => {
                 console.log("fetchCampaignDetails Pending");
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
                 console.log("fetchCampaignDetails Fulfilled:", action.payload);
                state.loading = false;
                const groupedEmails = groupEmailsByRecipient(action.payload.reverse());
                state.contacts = groupedEmails.map(group => {
                     const storedState = localStorage.getItem(`aiState_${group.primaryEmail.recipient_email}`);
                      if (storedState) {
                        return { ...group, isAiStopped: storedState === 'true' };
                    }
                      return group;
                })
            })
            .addCase(fetchCampaignDetails.rejected, (state, action) => {
                 console.error("fetchCampaignDetails Rejected:", action.error.message);
                state.loading = false;
               state.error = action.error.message
            })
            .addCase(stopAiReplyAction.pending, (state, action) => {
                 console.log("stopAiReplyAction Pending:", action.meta.arg.index);
                 state.stoppingAi = {
                    ...state.stoppingAi,
                    [action.meta.arg.index]: true
                }
            })
             .addCase(stopAiReplyAction.fulfilled, (state, action) => {
                 console.log("stopAiReplyAction Fulfilled:", action.payload);
                 state.stoppingAi = {
                    ...state.stoppingAi,
                   [action.payload.index]: false
                }
                  state.contacts = state.contacts.map(contact => {
                    if (contact.primaryEmail.recipient_email === action.payload.recipientEmail) {
                        localStorage.setItem(`aiState_${action.payload.recipientEmail}`, 'true');
                        return {
                            ...contact,
                            isAiStopped: true
                        };
                    }
                    return contact;
                });
            })
            .addCase(stopAiReplyAction.rejected, (state, action) => {
                  console.error("stopAiReplyAction Rejected:", action.error.message);
                state.stoppingAi = {
                    ...state.stoppingAi,
                    [action.meta.arg.index]: false
                }
                 state.error = action.error.message

            })
            .addCase(resumeAiReplyAction.pending, (state, action) => {
                  console.log("resumeAiReplyAction Pending:", action.meta.arg.index);
                state.resumingAi = {
                    ...state.resumingAi,
                    [action.meta.arg.index]: true
                }
            })
            .addCase(resumeAiReplyAction.fulfilled, (state, action) => {
                 console.log("resumeAiReplyAction Fulfilled:", action.payload);
                state.resumingAi = {
                    ...state.resumingAi,
                    [action.payload.index]: false
                }
                  state.contacts = state.contacts.map(contact => {
                    if (contact.primaryEmail.recipient_email === action.payload.recipientEmail) {
                          localStorage.setItem(`aiState_${action.payload.recipientEmail}`, 'false');
                        return {
                            ...contact,
                            isAiStopped: false
                        };
                    }
                    return contact;
                });
            })
            .addCase(resumeAiReplyAction.rejected, (state, action) => {
                 console.error("resumeAiReplyAction Rejected:", action.error.message);
                 state.resumingAi = {
                    ...state.resumingAi,
                    [action.meta.arg.index]: false
                }
                 state.error = action.error.message

            })
    },
});

const groupEmailsByRecipient = (emails) => {
    const emailGroups = {};

    emails.forEach((email) => {
        const recipientEmail = email.email_logs.recipient_email;

        if (!emailGroups[recipientEmail]) {
            emailGroups[recipientEmail] = {
                primaryEmail: email.email_logs,
                allEmails: [],
                latestReply: null,
                recipient_email: recipientEmail,
                isAiStopped: !!email.email_logs.is_ai_stopped, // Initialize from db
            };
        }

        emailGroups[recipientEmail].allEmails.push(email);

        // Update the latest reply
        if (
            email.processed_emails &&
            (!emailGroups[recipientEmail].latestReply ||
                new Date(email.processed_emails.created_at) >
                new Date(emailGroups[recipientEmail].latestReply.created_at))
        ) {
            emailGroups[recipientEmail].latestReply = email.processed_emails;
        }
    });

    const sortedGroups = Object.values(emailGroups).sort((a, b) => {
        const aHasReply = !!a.latestReply;
        const bHasReply = !!b.latestReply;

        if (aHasReply && !bHasReply) {
            return -1;
        }
        if (!aHasReply && bHasReply) {
            return 1;
        }

        const dateA = a.latestReply?.created_at || a.primaryEmail.created_at;
        const dateB = b.latestReply?.created_at || b.primaryEmail.created_at;
        return new Date(dateB) - new Date(dateA);
    });
    return sortedGroups;
};


export const { openPopup, closePopup } = campaignDetailsSlice.actions;
export default campaignDetailsSlice.reducer;