// src/page/Logout.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser, clearMessage } from '../features/authSlice'; 

const Logout = () => {
   
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(clearMessage())
      dispatch(logoutUser());
      navigate('/');

    }, [dispatch, navigate]);

    return (
        <div>
            <h1>Logged out successfully</h1> 
        </div>
    );
};

export default Logout;