import { configureStore } from '@reduxjs/toolkit';
import campaignReducer from '../features/campaignSlice';
import campaignButtonReducer from '../features/campaignButtonSlice';
import navigationReducer from '../features/navigationSlice';
import { persistMiddleware } from './Middleware/persistMiddleware';
import authReducer from '../features/authSlice';
import detailsTabReducer from '../features/detailsTabSlice';
import campaignDetailsReducer from '../features/campaignDetailsSlice'
import detailsTabScheduleReducer from '../features/detailsTabScheduleSlice' 
import detailsTabLeadsReducer from '../features/detailsTabLeadsSlice'
import dataTableReducer from '../features/dataTableSlice' 
import detailsTabSequencesReducer from '../features/detailsTabSequencesSlice'

// import your reducers


const storedNavigationState = localStorage.getItem('navigationState');
const initialState = {
    navigation :  storedNavigationState ? JSON.parse(storedNavigationState) : {currentPath: "/"},
}
export const store = configureStore({
    reducer: {
        campaigns: campaignReducer,
        campaignButton: campaignButtonReducer,
        navigation : navigationReducer,
        auth : authReducer,
        detailsTab: detailsTabReducer,
        campaignDetails: campaignDetailsReducer,
        detailsTabSchedule: detailsTabScheduleReducer,
        detailsTabLeads: detailsTabLeadsReducer, 
        dataTable: dataTableReducer,
        detailsTabSequences: detailsTabSequencesReducer,
      },
      middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(persistMiddleware),
      preloadedState: initialState
});

export default store;
