import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CampaignData, CampaignDataSaveEdit } from '../services/authFunctions';

// Async thunk for fetching campaign data
export const fetchCampaignData = createAsyncThunk(
    'detailsTabSequences/fetchCampaignData',
    async (campaignId, { rejectWithValue }) => {
        try {
            const campaignsData = await CampaignData(campaignId);
            if (Array.isArray(campaignsData)) {
                const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                if (filteredCampaign) {
                    return filteredCampaign;
                } else {
                    return rejectWithValue('Campaign not found');
                }
            } else {
                console.error("Expected an array, but received:", campaignsData);
                return rejectWithValue('Campaign data is not in the expected format.');
            }
        } catch (err) {
            console.error("Error fetching campaigns:", err);
            return rejectWithValue('Failed to fetch campaigns');
        }
    }
   );
   
   // Async thunk for saving campaign data
   export const saveCampaignData = createAsyncThunk(
    'detailsTabSequences/saveCampaignData',
    async (payload, { rejectWithValue }) => {
        try {
           const response = await CampaignDataSaveEdit(payload);
           if (response && response.id) {
            return response;
           }
           else {
             return rejectWithValue('Unexpected API response');
           }
        } catch (error) {
            console.error("Error saving campaign data:", error);
            return rejectWithValue('Error updating stage');
        }
    }
   );
   
   const initialState = {
    campaign: null,
    loading: false,
    error: null,
    selectedStage: null,
    subject: '',
    description: '',
   };
   
   const detailsTabSequencesSlice = createSlice({
    name: 'detailsTabSequences',
    initialState,
    reducers: {
        setSelectedStage: (state, action) => {
            state.selectedStage = action.payload;
            state.subject = action.payload?.subject || '';
            state.description = action.payload?.description || '';
        },
        setSubject: (state, action) => {
            state.subject = action.payload;
        },
        setDescription: (state, action) => {
            state.description = action.payload;
        },
         resetState: (state) => {
           state.campaign = null;
            state.loading = false;
             state.error = null;
             state.selectedStage = null;
             state.subject = '';
              state.description = '';
        },
        addStage : (state, action) => {
             const newStage = action.payload
               state.campaign = state.campaign ? {...state.campaign, stages: state.campaign.stages ? [...state.campaign.stages, newStage] : [newStage]} : { stages : [newStage]}
               state.selectedStage = newStage
               state.subject = ''
               state.description = ''
        },
        updateCampaignState: (state, action) => {
              state.campaign = action.payload;
             if(state.selectedStage && state.campaign.stages.findIndex(stage => stage.id === state.selectedStage.id) === -1) {
                const newIndex = state.campaign.stages.findIndex(stage => stage.id === state.selectedStage.id - 1)
                if(newIndex > -1 ) {
                    state.selectedStage = state.campaign.stages[newIndex]
                    state.subject = state.campaign.stages[newIndex].subject || ''
                     state.description = state.campaign.stages[newIndex].description || ''
                 }
                else if(state.campaign.stages.length > 0) {
                       state.selectedStage = state.campaign.stages[0]
                    state.subject = state.campaign.stages[0].subject || ''
                     state.description = state.campaign.stages[0].description || ''
                }
               else {
                  state.selectedStage = null
                    state.subject = ''
                    state.description = ''
                }
           }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaignData.pending, (state) => {
                state.loading = true;
                  state.error = null;
            })
            .addCase(fetchCampaignData.fulfilled, (state, action) => {
                state.loading = false;
                state.campaign = action.payload;
                if (action.payload?.stages && action.payload.stages.length > 0) {
                    state.selectedStage = action.payload.stages[0];
                    state.subject = action.payload.stages[0].subject || '';
                    state.description = action.payload.stages[0].description || '';
                }
                   state.error = null;
            })
            .addCase(fetchCampaignData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(saveCampaignData.pending, (state) => {
                 state.loading = true;
                   state.error = null;
            })
             .addCase(saveCampaignData.fulfilled, (state, action) => {
                state.loading = false;
                state.campaign = action.payload;
                 if(state.selectedStage && state.campaign.stages.findIndex(stage => stage.id === state.selectedStage.id) === -1) {
                    const newIndex = state.campaign.stages.findIndex(stage => stage.id === state.selectedStage.id - 1)
                    if(newIndex > -1 ) {
                        state.selectedStage = state.campaign.stages[newIndex]
                         state.subject = state.campaign.stages[newIndex].subject || ''
                        state.description = state.campaign.stages[newIndex].description || ''
                     }
                     else if(state.campaign.stages.length > 0) {
                          state.selectedStage = state.campaign.stages[0]
                        state.subject = state.campaign.stages[0].subject || ''
                        state.description = state.campaign.stages[0].description || ''
                    }
                     else {
                           state.selectedStage = null
                        state.subject = ''
                        state.description = ''
                    }
   
                 }
   
                   state.error = null;
            })
            .addCase(saveCampaignData.rejected, (state, action) => {
                state.loading = false;
                 state.error = action.payload;
            });
    },
   });
   
   
   export const { setSelectedStage, setSubject, setDescription, resetState, addStage, updateCampaignState } = detailsTabSequencesSlice.actions;
   export default detailsTabSequencesSlice.reducer;