import React, { useState } from 'react';

const EmailDetailsPopup = ({ isOpen, onClose, popupData, popupEmail }) => {
    const [openSections, setOpenSections] = useState({});

    const handleToggle = (index) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    if (!isOpen || !popupData) return null;

    const getPlainText = (htmlString) => {
        if (!htmlString) return 'N/A';
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || "N/A";
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleDateString('en-US');
    };

     const formatTime = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const uniqueEmails = popupData.allEmails.filter((email, index, self) => {
        const createdAt = email.processed_emails?.created_at;
        return self.findIndex(e => e.processed_emails?.created_at === createdAt) === index;
    });


    const hasResponses = uniqueEmails.some(email => email.processed_emails?.email_body || email.processed_emails?.response_sent);


    return (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }} >
            <div className="modal-dialog modal-dialog-centered modal-xl" >
                <div className="modal-content w-100" style={{ width: '100%', important: true }}>
                    <div className="modal-header">
                        <h5 className="modal-title">{popupEmail}</h5>
                        <button type="button" className="btn-close" onClick={onClose}  ></button>
                    </div>
                    <div className="modal-body " style={{ position: 'relative', maxHeight: '400px', overflow: 'auto' }} >
                        <table className="table mb-0">
                            <thead>
                                <tr className="table-light">
                                    <th>Recipient</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Status</th>
                                    <th>Sentiment</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hasResponses ? (
                                    uniqueEmails.map((email, emailIndex) => {
                                        if (!email.processed_emails) {
                                            return null;
                                        }
                                        const { email_body, response_sent,  sentiment } = email.processed_emails;
                                        const plainTextRecipientMessage = getPlainText(email_body)
                                        const plainTextAiResponse = getPlainText(response_sent)
                                          const recipient_created_at = email.processed_emails?.created_at;
                                        const response_sent_at = email.processed_emails?.updated_at;
                                        return (
                                            <React.Fragment key={`${emailIndex}-${recipient_created_at}`}>
                                                {/* Row for Recipient Message */}
                                                {email_body && (
                                                    <>
                                                        <tr>
                                                            <td>{popupEmail}</td>
                                                            <td>{formatDate(recipient_created_at)}</td>
                                                            <td>{formatTime(recipient_created_at)}</td>
                                                            <td>
                                                                <span className="badge bg-success">Received</span>
                                                            </td>
                                                            <td>{sentiment || 'N/A'}</td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-sm"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#recipientMessage${emailIndex}`}
                                                                    aria-expanded={openSections[emailIndex] ? 'true' : 'false'}
                                                                    aria-controls={`recipientMessage${emailIndex}`}
                                                                    onClick={() => handleToggle(emailIndex)}
                                                                >
                                                                    <i
                                                                        className="arrow-icon"
                                                                        style={{ fontSize: '16px', color: '#333' }}
                                                                    >
                                                                        {openSections[emailIndex] ? '↓' : '↑'}
                                                                    </i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr className="collapse" id={`recipientMessage${emailIndex}`}>
                                                            <td colSpan="7" className="bg-light">
                                                                <strong>Message:</strong> {plainTextRecipientMessage || 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}

                                                {/* Row for AI Response */}
                                                {response_sent && (
                                                    <>
                                                        <tr>
                                                            <td>AI Response</td>
                                                            <td>{formatDate(response_sent_at)}</td>
                                                            <td>{formatTime(response_sent_at)}</td>
                                                            <td>
                                                                <span className="badge bg-primary">Sent</span>
                                                            </td>
                                                            <td></td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-sm"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#aiResponse${emailIndex}`}
                                                                    aria-expanded={openSections[emailIndex + 'ai'] ? 'true' : 'false'}
                                                                    aria-controls={`aiResponse${emailIndex}`}
                                                                    onClick={() => handleToggle(emailIndex + 'ai')}
                                                                >
                                                                    <i
                                                                        className="arrow-icon"
                                                                        style={{ fontSize: '16px', color: '#333' }}
                                                                    >
                                                                        {openSections[emailIndex + 'ai'] ? '↓' : '↑'}
                                                                    </i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr className="collapse" id={`aiResponse${emailIndex}`}>
                                                            <td colSpan="7" className="bg-light">
                                                                <strong>AI Response:</strong> {plainTextAiResponse || 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No response data found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailDetailsPopup;