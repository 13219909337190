import React from "react";
import Summarycard from "../../../common/Summary_card";
import BarChart from "../../../common/BarChart";
import TabAnalytics from "./TabDetailsAnalytics";
import TabLeads from "./TabDetailsLeads";
import DetailsTabSequences from "./TabDetailsSequences";
import DetailsTabSchedule from "./TabDetailsSchedule";
import SendEmails from "../Email-send";
import CampaignDataImport from "./DataImport";
import CampaignButton from "./CampaignButton";
import CampaignseSeandEmailData from "./CampaignseSeandEmailDetails";
import EmailSettings from "./TabOptions";
import { ContactsData, CampaignData } from '../../../services/authFunctions';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'; // Import useParams


const DetailsTab = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { campaignId } = useParams(); // Get campaignId from URL
    const [campaignData, setCampaignData] = useState(null);
    console.log("leads data in tabdetails", data);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const contacts = await ContactsData();
                const trimmedContacts = contacts.map(contact => {
                    const trimmedContact = {};
                    Object.keys(contact).forEach(key => {
                        if (typeof contact[key] === 'string') {
                            trimmedContact[key] = contact[key].trim();
                        } else {
                            trimmedContact[key] = contact[key];
                        }
                    });
                    return trimmedContact;
                });
                setData(trimmedContacts);

            } catch (error) {
                setError("Failed to fetch Data");
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchCampaignData = async () => {
            try {
                const data = await CampaignData(campaignId);
                setCampaignData(data);
            } catch (error) {
                 setError("Failed to fetch Campaign Data");
                setLoading(false);
                console.error("Error fetching campaign data:", error);
            }
        };

        if(campaignId) {
            fetchCampaignData();
        }

    }, [campaignId]);

    return (
        <div className="px-4 ff-inter py-3">
            <div className="d-flex w-100 justify-content-between border-bottom align-items-center ">
                <ul className="nav nav-tabs text-center border-0" id="myTab" role="tablist">
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link active border-0 border-bottom text-dark fs-14 tc-111111 p-3 d-inline-bolck" id="analytics-tab" data-bs-toggle="tab" href="#analytics" role="tab" aria-controls="analytics" aria-selected="true">
                            Analytics
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="leads-tab" data-bs-toggle="tab" href="#leads" role="tab" aria-controls="leads" aria-selected="false">
                            Leads
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="sequences-tab" data-bs-toggle="tab" href="#sequences" role="tab" aria-controls="sequences" aria-selected="false">
                            Sequences
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="schedule-tab" data-bs-toggle="tab" href="#schedule" role="tab" aria-controls="schedule" aria-selected="false">
                            Schedule
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="options-tab" data-bs-toggle="tab" href="#options" role="tab" aria-controls="options" aria-selected="false">
                            Details
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="Options-tab" data-bs-toggle="tab" href="#Options" role="tab" aria-controls="subsequences" aria-selected="false">
                            Options
                        </a>
                    </li>
                </ul>
                {/* Pass emailIds and campaignId to CampaignButtonStart */}

                <CampaignButton emailIds={data} campaignId={campaignId} campaignData={campaignData} />


            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                    <TabAnalytics />
                </div>
                <div className="tab-pane fade" id="leads" role="tabpanel" aria-labelledby="leads-tab">
                    <div className="p-3 p-md-4">
                        <TabLeads />
                    </div>
                </div>
                <div className="tab-pane fade" id="sequences" role="tabpanel" aria-labelledby="sequences-tab">
                    <div className="p-3 p-md-4">
                        <DetailsTabSequences />
                    </div>
                </div>
                <div className="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
                    <div className="p-3 p-md-4">
                        <DetailsTabSchedule />
                    </div>
                </div>
                <div className="tab-pane fade" id="options" role="tabpanel" aria-labelledby="options-tab">
                    <div className="p-3 p-md-4">
                        {/* <SendEmails/>*/}
                        {<CampaignseSeandEmailData></CampaignseSeandEmailData>}
                    </div>
                </div>
                <div className="tab-pane fade" id="Options" role="tabpanel" aria-labelledby="subsequences-tab">
                    <div className="p-3 p-md-4">
                        <EmailSettings></EmailSettings>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsTab;