import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Pagination from './Pagination';
import ItemsPerPageSelect from './ItemsPerPageSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentPage,
    setItemsPerPage,
    setSelectedItems,
    setPopoverRow,
    toggleSelectItem
} from '../features/dataTableSlice';

const DataTableComponent = ({
    tableData = [],
    columns,
    showCheckboxes,
    showEditButton,
    showButtonAction,
    addLink,
    basePath,
}) => {
    const dispatch = useDispatch();
    const { currentPage, itemsPerPage, selectedItems, popoverRow } = useSelector(
        (state) => state.dataTable
    );

    const popoverRef = useRef(null);

    // Calculate pagination
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => dispatch(setCurrentPage(pageNumber));

    // Handle "select all" checkbox
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allSelected = currentItems.map((item) => item.id);
            dispatch(setSelectedItems(allSelected));
        } else {
            dispatch(setSelectedItems([]));
        }
    };

    // Handle individual row checkbox
    const handleSelectUser = (id) => {
        dispatch(toggleSelectItem(id));
    };

    // Handle popover toggle
    const togglePopover = (rowId) => {
        dispatch(setPopoverRow(popoverRow === rowId ? null : rowId));
    };

    // Handle button actions
    const handleEdit = (row) => {
        alert(`Edit row data: ${JSON.stringify(row)}`);
        dispatch(setPopoverRow(null)); // Close popover
    };

    const handleDelete = (row) => {
        alert(`Delete row data: ${JSON.stringify(row)}`);
        dispatch(setPopoverRow(null)); // Close popover
    };
    const handleClickOutside = (event) => {
         if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            dispatch(setPopoverRow(null));
        }
    };

     useEffect(() => {
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
             document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch]);

    return (
        <>
            <div className="table-responsive">
                <table className="table fs-14">
                    <thead>
                        <tr>
                            {showCheckboxes && (
                                <th key="checkbox">
                                    <input
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={
                                            selectedItems.length === currentItems.length &&
                                            currentItems.length > 0
                                        }
                                    />
                                </th>
                            )}

                            {showEditButton && <th key="edit">Actions</th>}

                            {columns.map((col) => (
                                <th className="text-nowrap" key={col.data}>
                                    {col.title}
                                </th>
                            ))}

                            {showButtonAction && <th key="actions">Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.length > 0 ? (
                            currentItems.map((row) => (
                                <tr key={row.id}>
                                    {showCheckboxes && (
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(row.id)}
                                                onChange={() => handleSelectUser(row.id)}
                                            />
                                        </td>
                                    )}

                                    {showEditButton && (
                                        <td>
                                            <button
                                                onClick={() => togglePopover(row.id)}
                                                className="border-0 bg-transparent position-relative"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                >
                                                    <circle cx="8" cy="4" r="1.5" fill="#747474" />
                                                    <circle cx="8" cy="8" r="1.5" fill="#747474" />
                                                    <circle cx="8" cy="12" r="1.5" fill="#747474" />
                                                </svg>
                                            </button>

                                            {popoverRow === row.id && (
                                                <div
                                                   ref={popoverRef}
                                                    className="popover position-absolute bg-white border shadow p-2"

                                                >
                                                    <button
                                                        className="btn btn-sm btn-link text-dark"
                                                        onClick={() => handleEdit(row)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className="btn btn-sm btn-link text-danger"
                                                        onClick={() => handleDelete(row)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    )}

                                    {columns.map((col) => (
                                        <td key={`${row.id}-${col.data}`}>
                                            {col.data === 'name' ? (
                                                addLink ? (
                                                    <Link
                                                        to={`/${basePath}/${row.id}`}
                                                        className="text-decoration-none text-dark"
                                                    >
                                                        {row[col.data]}
                                                    </Link>
                                                ) : (
                                                    <span>{row[col.data]}</span>
                                                )
                                            ) : (
                                                row[col.data]
                                            )}
                                        </td>
                                    ))}

                                    {showButtonAction && (
                                        <td>
                                            <img
                                                src="assets/img/svg/play-green.svg"
                                                width="24"
                                                alt=""
                                            />
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={
                                        columns.length +
                                        (showCheckboxes ? 1 : 0) +
                                        (showEditButton ? 1 : 0)
                                    }
                                >
                                    No results found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="d-flex justify-content-between mb-3">
                <div className="w-200">
                    Rows per page
                    <ItemsPerPageSelect
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={(value) => dispatch(setItemsPerPage(value))}
                        className="mxw-110"
                    />
                </div>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                />
            </div>
        </>
    );
};

export default DataTableComponent;