import React from "react";  
import Summarycard from "../../../common/Summary_card";
import BarChart from "../../../common/BarChart";

const DetailsTabAnalytics = () => {
   
    return (  
        <div className="px-4 ff-inter py-3"> 
            <div className="row">
                <div className="col-12 d-flex mb-3">
                    <div className="me-auto">
                        Status:
                        <spam className="text-white py-1 px-3 rounded-pill bg-74C4FF d-inline-block mw-110 text-center fs-14 mx-3">Active</spam>
                        <div className="d-inline-block" style={{ width: "100px" }} >
                            <div className=" fs-12">25%</div>
                            <div className="progress" style={{ height: " 6px" }} >
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button type="button" className="btn border me-3 fs-14"><img src="assets/img/svg/download.svg" alt="" /> Download </button>

                        <div className="dropdown me-2">
                            <button className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2 h-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="text-start text-truncate w-200 px-1" id="timeDropdown">This Week</div>
                            </button>
                            <ul className="dropdown-menu fs-14" aria-labelledby="timeDropdown">
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' This Week', 'timeDropdown')"> This Week</button></li>
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' Yesterday', 'timeDropdown')">  Yesterday</button></li>
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' This Week', 'timeDropdown')">This Week</button></li>
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' This Month', 'timeDropdown')">This Month</button></li>
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' Last Month', 'timeDropdown')"> Last Month</button></li>
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' Year to Date', 'timeDropdown')">Year to Date</button></li>
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' Last 12 Month', 'timeDropdown')">  Last 12 Month</button></li>
                                <li><button className="dropdown-item" type="button" onclick="changeDropdown(null,  ' Custom', 'timeDropdown')"> Custom</button></li>
                            </ul>
                        </div>
                        <div className="dropdown me-3">
                            <button className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2 " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="text-start text-truncate" id="statusDropdown">
                                    <img id="statusImage" src="assets/img/svg/settings.svg" />
                                </div>
                            </button>
                            <ul className="dropdown-menu fs-14 px-2" aria-labelledby="statusDropdown">
                                <li><input type="checkbox" /> Sequence started</li>
                                <li><input type="checkbox" /> Open rate</li>
                                <li><input type="checkbox" /> Click rate</li>
                                <li><input type="checkbox" /> Reply rate</li>
                                <li><input type="checkbox" /> Positive Reply Rate</li>
                                <li><input type="checkbox" /> Opportunities</li>
                                <li><input type="checkbox" /> Conversions</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 row-cols-1 row-cols-md-3 row-cols-lg-5 mb-3 mb-mb-4 mb-lg-5">
                <div className="cols">
                    <Summarycard summaryTitlte="Sequence started" tooltipCodes="8" countForNumber="3,050" count={9.2} simbol={true} showCountIndicator={true} showInfoImg={true} className="border" />
                </div>
                <div className="cols"> 
                    <Summarycard summaryTitlte="Opened emails rateinfo" tooltipCodes="8" countForNumber="17,497" count={9.2} simbol={true} showCountIndicator={true} showInfoImg={true} className="border" />
                </div>
                <div className="cols"> 
                    <Summarycard summaryTitlte="Clicked emails rate" tooltipCodes="8" countForNumber="17,497" count={9.2} simbol={true} showCountIndicator={true} showInfoImg={true} className="border" />
                </div>
                <div className="cols"> 
                    <Summarycard summaryTitlte="Conversions" tooltipCodes="8" countForNumber="10" count={20.2} simbol={true} showCountIndicator={true} showInfoImg={true} className="border"/>
                </div>
                <div className="cols"> 
                    <Summarycard summaryTitlte="Opportunities" tooltipCodes="8" countForNumber="10" count={20.2} simbol={true} showCountIndicator={true} showInfoImg={true} className="border" />
                    
                </div>
            </div>

            <div className="row  mb-3 mb-mb-4 mb-lg-5">
                <div className="col-12 col-md-12">
                    <div className="bg-F5F8FF rounded-4 p-3">
                        <BarChart></BarChart>
                    </div>
                </div>
            </div>
 

        </div>
  );
};

export default DetailsTabAnalytics;
