import React from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../common/Header.js";
import DetailsHeader from './DetailsCommponents/Header.js';
import DetailsTab from './DetailsCommponents/TabDetails.js';
import { CampaignData } from '../../services/authFunctions';
import { useState, useEffect } from 'react';


const CampaignDetails = () => {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState(null);
     const [error, setError] = useState(null);
     const [loading, setLoading] = useState(true);

    useEffect(() => {
    const fetchCampaignData = async () => {
            try {
                const campaignsData = await CampaignData(campaignId);


                 if (Array.isArray(campaignsData)) {
                     const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                     if (filteredCampaign) {
                         setCampaign(filteredCampaign);
                     } else {
                        setError('Campaign not found');
                    }
                } else {
                    console.error("Expected an array, but received:", campaignsData);
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);

            } catch (err) {
                 console.error("Error fetching campaigns:", err);
                 setError('Failed to fetch campaigns');
                setLoading(false);
            }
        };

    if(campaignId) {
        fetchCampaignData();
    }
    }, [campaignId]);

    if (loading) {
        return (
             <div className="loading-indicator">
                            <span>Loading campaign details...</span>
                            {/* You can add a spinner here */}
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
            </div>
        )
    }
    if (error) {
        return <div className="error-message">Error: {error}</div>
    }
     if(!campaign){
        return <p>No Campaign Data</p>
     }

    return (
        <>
            <div className="w-100 d-flex">
                <Header />
                <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                    <DetailsHeader CampaignsName={campaign.name} />
                    <DetailsTab campaignId={campaignId} campaignData={campaign}/>
                </div>
            </div>
        </>
    );
};

export default CampaignDetails;