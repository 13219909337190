import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCampaignDetails,
    openPopup,
    closePopup,
    stopAiReplyAction,
    resumeAiReplyAction,
} from '../../../features/campaignDetailsSlice';
import EmailDetailsPopup from './EmailDetailsPopup';
import CampaignDataTable from './CampaignDataTable';

const CampaignSendEmailData = () => {
    const { campaignId } = useParams();
    const dispatch = useDispatch();
    const {
        contacts,
        loading,
        error,
        popupData,
        isPopupOpen,
        popupEmail,
        stoppingAi,
        resumingAi,
    } = useSelector((state) => state.campaignDetails);


    useEffect(() => {
        if (campaignId) {
            dispatch(fetchCampaignDetails(campaignId));
        }
    }, [dispatch, campaignId]);

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleDateString('en-US');
    };

    const formatTime = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const openPopupHandler = (emailGroup) => {
        console.log("Opening popup for email:", emailGroup);
        dispatch(openPopup(emailGroup));
    };

    const closePopupHandler = () => {
        dispatch(closePopup());
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-danger">Error: {error}</div>;

    const getPlainTextMainTable = (htmlString) => {
        if (!htmlString) return 'N/A';
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || "N/A";
    }

      const handleStopAiReply = async (recipientEmail, index) => {
        dispatch(stopAiReplyAction({ recipientEmail, campaignId, index }));
    };

    const handleResumeAiReply = async (recipientEmail, index) => {
        dispatch(resumeAiReplyAction({ recipientEmail, campaignId, index }));
    };


    return (
        <div className="container my-4">
            <h3 className="mb-4">Campaign Recipient Status Details</h3>
            <div className="row">
                <div className="col-md-2">
                    <div className="border rounded p-3 mb-3">
                        <h5 className="mb-3">Filters</h5>
                        <div className="mb-2">
                            <label className="form-label">Status</label>
                            <input type="text" className="form-control" placeholder="Enter status" />
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Gender</label>
                            <input type="text" className="form-control" placeholder="Enter gender" />
                        </div>
                        <button className="btn btn-primary btn-sm w-100">Apply Filters</button>
                    </div>
                </div>
                <div className="col-md-10">
                    <CampaignDataTable
                        contacts={contacts}
                        formatDate={formatDate}
                        formatTime={formatTime}
                        openPopup={openPopupHandler}
                        handleStopAiReply={handleStopAiReply}
                        handleResumeAiReply={handleResumeAiReply}
                        stoppingAi={stoppingAi}
                        resumingAi={resumingAi}
                        getPlainTextMainTable={getPlainTextMainTable}
                    />
                </div>
            </div>
            {/* Popup */}
            {popupData && (
              <EmailDetailsPopup
                  isOpen={isPopupOpen}
                  onClose={closePopupHandler}
                  popupData={popupData}
                  popupEmail={popupEmail}
              />
            )}

        </div>
    );
};

export default CampaignSendEmailData;