import React, { useEffect } from "react";
import Summarycard from "../../../common/Summary_card";
import BarChart from "../../../common/BarChart";
import TabAnalytics from "./TabDetailsAnalytics";
import TabLeads from "./TabDetailsLeads";
import DetailsTabSequences from "./TabDetailsSequences";
import DetailsTabSchedule from "./TabDetailsSchedule";
import SendEmails from "../Email-send";
import CampaignDataImport from "./DataImport";
import CampaignButton from "./CampaignButton";
import CampaignseSeandEmailData from "./CampaignseSeandEmailDetails";
import EmailSettings from "./TabOptions";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactsData, setActiveTab, resetState } from '../../../features/detailsTabSlice';


const DetailsTab = ({ campaignId, campaignData }) => {
    const dispatch = useDispatch();
    const { data, loading, error, activeTab } = useSelector((state) => state.detailsTab);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const tabFromUrl = searchParams.get("tab");
        if (tabFromUrl) {
            dispatch(setActiveTab(tabFromUrl));
        } else {
            dispatch(setActiveTab("analytics"));
        }
    }, [searchParams, dispatch]);

    const handleTabChange = (tabId) => {
        dispatch(setActiveTab(tabId));
        setSearchParams({ tab: tabId });
    };
    useEffect(() => {
         dispatch(fetchContactsData());
         return () => {
          dispatch(resetState())
         }
     }, [dispatch]);


    if (loading) {
          return (
             <div className="loading-indicator">
                <span>Loading Tab Details...</span>
                <div className="spinner-border" role="status">
                   <span className="visually-hidden">Loading...</span>
                 </div>
           </div>
          );
    }
    if (error) {
        return <div className="error-message">Error: {error}</div>;
    }

    return (
       <div className="px-4 ff-inter py-3">
            <div className="d-flex w-100 justify-content-between border-bottom align-items-center ">
                <ul className="nav nav-tabs text-center border-0" id="myTab" role="tablist">
                    <li className="nav-item  " role="presentation">
                        <a
                            className={`nav-link border-0 border-bottom text-dark fs-14 tc-111111 p-3 d-inline-bolck ${activeTab === "analytics" ? "active" : ""}`}
                            id="analytics-tab"
                            data-bs-toggle="tab"
                            href="#analytics"
                            role="tab"
                            aria-controls="analytics"
                            aria-selected={activeTab === "analytics"}
                            onClick={() => handleTabChange("analytics")}
                        >
                            Analytics
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a
                            className={`nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck ${activeTab === "leads" ? "active" : ""}`}
                            id="leads-tab"
                            data-bs-toggle="tab"
                            href="#leads"
                            role="tab"
                            aria-controls="leads"
                            aria-selected={activeTab === "leads"}
                            onClick={() => handleTabChange("leads")}
                        >
                            Leads
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a
                            className={`nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck ${activeTab === "sequences" ? "active" : ""}`}
                            id="sequences-tab"
                            data-bs-toggle="tab"
                            href="#sequences"
                            role="tab"
                            aria-controls="sequences"
                            aria-selected={activeTab === "sequences"}
                            onClick={() => handleTabChange("sequences")}
                        >
                            Sequences
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a
                            className={`nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck ${activeTab === "schedule" ? "active" : ""}`}
                            id="schedule-tab"
                            data-bs-toggle="tab"
                            href="#schedule"
                            role="tab"
                            aria-controls="schedule"
                            aria-selected={activeTab === "schedule"}
                            onClick={() => handleTabChange("schedule")}
                        >
                            Schedule
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a
                            className={`nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck ${activeTab === "details" ? "active" : ""}`}
                            id="options-tab"
                            data-bs-toggle="tab"
                            href="#options"
                            role="tab"
                            aria-controls="details"
                            aria-selected={activeTab === "details"}
                            onClick={() => handleTabChange("details")}
                        >
                            Details
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a
                           className={`nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck ${activeTab === "Options" ? "active" : ""}`}
                            id="Options-tab"
                            data-bs-toggle="tab"
                            href="#Options"
                            role="tab"
                            aria-controls="subsequences"
                            aria-selected={activeTab === "Options"}
                             onClick={() => handleTabChange("Options")}
                        >
                            Options
                        </a>
                    </li>
                </ul>
                {/* Pass emailIds and campaignId to CampaignButtonStart */}

                <CampaignButton emailIds={data} campaignId={campaignId} campaignData={campaignData} />


            </div>
            <div className="tab-content" id="myTabContent">
                <div className={`tab-pane fade ${activeTab === "analytics" ? "show active" : ""}`} id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                    <TabAnalytics />
                </div>
                <div className={`tab-pane fade ${activeTab === "leads" ? "show active" : ""}`} id="leads" role="tabpanel" aria-labelledby="leads-tab">
                    <div className="p-3 p-md-4">
                        <TabLeads />
                    </div>
                </div>
                <div className={`tab-pane fade ${activeTab === "sequences" ? "show active" : ""}`} id="sequences" role="tabpanel" aria-labelledby="sequences-tab">
                    <div className="p-3 p-md-4">
                        <DetailsTabSequences />
                    </div>
                </div>
                <div className={`tab-pane fade ${activeTab === "schedule" ? "show active" : ""}`} id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
                    <div className="p-3 p-md-4">
                        <DetailsTabSchedule />
                    </div>
                </div>
                <div className={`tab-pane fade ${activeTab === "details" ? "show active" : ""}`} id="details" role="tabpanel" aria-labelledby="options-tab">
                    <div className="p-3 p-md-4">
                        {/* <SendEmails/>*/}
                        {<CampaignseSeandEmailData></CampaignseSeandEmailData>}
                    </div>
                </div>
                  <div className={`tab-pane fade ${activeTab === "Options" ? "show active" : ""}`} id="Options" role="tabpanel" aria-labelledby="subsequences-tab">
                    <div className="p-3 p-md-4">
                        <EmailSettings></EmailSettings>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsTab;