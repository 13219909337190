import React, { useState, useEffect } from "react";
import Header from "../../common/Header.js";
import PageHeader from "./Header.js";
import { gsuiteDataShowdata } from '../../services/authFunctions';

const EmailAccounts = () => {
    // State to hold email data
    const [emailAccounts, setEmailAccounts] = useState([]);

    // Fetch email accounts data
    useEffect(() => {
        const fetchEmailAccounts = async () => {
            const data = await gsuiteDataShowdata(); // Fetch the data from API
            console.log("Fetched data:", data); // Log the response to see its structure

            // Check if GsuiteAccount exists and is an array
            if (data && data.GsuiteAccount && Array.isArray(data.GsuiteAccount)) {
                setEmailAccounts(data.GsuiteAccount); // Set the email accounts if the array is present
            } else {
                console.error("Data fetched is not in expected format:", data);
                setEmailAccounts([]); // Fallback to empty array if data is not in expected format
            }
        };

        fetchEmailAccounts();
    }, []);

    return (
        <div className="w-100 d-flex">
            <Header />
            <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                <PageHeader />
                <div className="px-4 ff-inter py-3">
                    <table className="table table-striped dataTable overflow-visible w-100">
                        <thead>
                            <tr>
                                <th><input className="form-check-input me-1" type="checkbox" value="" /></th>
                                <th>Email</th>
                                <th>Emails sent</th>
                                <th>Warmup emails</th>
                                <th>Health score</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailAccounts.length > 0 ? (
                                emailAccounts.map((account) => (
                                    <tr key={account.id}>
                                        <td><input className="form-check-input me-1" type="checkbox" value="" /></td>
                                        <td className="custom-pointer">{account.email}</td>
                                        <td className="custom-pointer">0 of 30</td>
                                        <td className="custom-pointer">72</td>
                                        <td className="custom-pointer">100%</td>
                                        <td>
                                            <img src="assets/img/svg/ata-icon-5.svg" width="24" alt="" /> 
                                            {account.is_active === 1 ? (
                                                <span className="badge bg-success">Verified Email</span>
                                            ) : (
                                                <span
                                                    className="badge bg-warning custom-pointer"
                                                    onClick={() => {
                                                        const openURLnewtab = `${window.API_URL}/api/gsuite/auth/${account.email}`;
                                                        window.open(openURLnewtab, '_blank');
                                                    }}
                                                >
                                                    Unverified Email
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EmailAccounts;
