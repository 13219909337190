import axios from 'axios';

const API_URL = process.env.REACT_APP_URL;
window.API_URL = API_URL;

export const fetchData = async (endpoint) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/${endpoint}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        console.log('API Response:', response);
        return response.data;
    } catch (error) {
        console.error('API Error', error);
        throw error;
    }
};

export const postData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem('token');
        const headers = token ? {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        } : { 'Content-Type': 'application/json' };

        const response = await axios.post(`${API_URL}/${endpoint}`, data, { headers });
        console.log("API Full Response:", response.data);
        return response.data;
    } catch (error) {
        console.error('API Error:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;
    }
};

export const putData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem('token');
        const headers = token ? {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        } : { 'Content-Type': 'application/json' };

        const requestUrl = `${API_URL}/${endpoint}`;
        console.log("Request URL:", requestUrl);
        const response = await axios.put(requestUrl, data, { headers });
        console.log("Response from putData:", response.data);
        return response.data;
    } catch (error) {
        console.error('API Error:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;
    }
};

export const importData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem('token');
        const headers = token ? {
            'Authorization': `Bearer ${token}`,
        } : {};
        if (data instanceof FormData) {
            delete headers['Content-Type'];
        } else {
            headers['Content-Type'] = 'application/json';
        }
        const response = await axios.post(`${API_URL}/${endpoint}`, data, { headers });
        console.log("API Full Response:", response.data);
        return response.data;
    } catch (error) {
        console.error('API Error:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;
    }
};


export const stopAiReply = async (recipientEmail, campaignId) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${API_URL}/api/stopAIEmail`, {
            recipient_email: recipientEmail, action: 'stop'
        },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
        console.log("Stop AI Reply API Response:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error stopping AI reply:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;
    }
};


export const resumeAiReply = async (recipientEmail, campaignId) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${API_URL}/api/stopAIEmail`, {
            recipient_email: recipientEmail,  action: 'resume'
        },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
        console.log("Resume AI Reply API Response:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error resuming AI reply:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;
    }
};