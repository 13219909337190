import React from 'react';

const CampaignDataTable = ({
  contacts,
  formatDate,
  formatTime,
  openPopup,
  handleStopAiReply,
  handleResumeAiReply,
  stoppingAi,
  resumingAi,
  getPlainTextMainTable,
}) => {
  return (
    <table className="table table-hover table-bordered">
      <thead className="table-light">
        <tr>
          <th>Sr.No</th>
          <th>Recipient Email</th>
          <th>Stage</th>
          <th>Date</th>
          <th>Time</th>
          <th>Status</th>
          <th>Message</th>
          <th>Sentiment</th>
          <th className="text-center">View Details</th>
          <th className="text-center">Stop AI</th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((emailGroup, index) => {
          console.log(`Rendering email group at index ${index}:`, emailGroup);
          const latestReply = emailGroup.latestReply;
          const isReceived = !!latestReply;
          const primaryEmail = emailGroup.primaryEmail;
          const isSent = primaryEmail.is_sent === 1;
          const emailBody = primaryEmail.body || 'N/A';
          const isAiStopped = emailGroup.isAiStopped;
          const plainTextMessage = getPlainTextMainTable(emailBody);
          const plainTextReply = isReceived && latestReply?.email_body
            ? getPlainTextMainTable(latestReply.email_body)
            : null;

          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{primaryEmail.recipient_email}</td>
              <td>{primaryEmail.stage || 'N/A'}</td>
              {/* Conditional Date and Time */}
              <td>
                {isReceived ? formatDate(latestReply.created_at) : formatDate(primaryEmail.created_at)}
              </td>
              <td>
                {isReceived ? formatTime(latestReply.created_at) : formatTime(primaryEmail.created_at)}
              </td>
              {/* Status Field */}
              <td>
                <span
                  className={`badge ${isReceived
                    ? 'bg-success'
                    : isSent
                      ? 'bg-warning text-dark'
                      : 'bg-secondary'
                    }`}
                >
                  {isReceived ? 'Received' : isSent ? 'Sent' : 'N/A'}
                </span>
              </td>
              {/* Recipient Message with Tooltip */}
              <td>
                {plainTextReply ? (
                  <span
                    title={plainTextReply}
                    className="text-truncate d-inline-block"
                    style={{
                      maxWidth: '200px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                    }}
                  >
                    {plainTextReply.split(' ').slice(0, 3).join(' ')}...
                  </span>
                ) : (
                  <span
                    title={plainTextMessage}
                    className="text-truncate d-inline-block"
                    style={{
                      maxWidth: '200px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                    }}
                  >
                    {plainTextMessage.split(' ').slice(0, 3).join(' ')}...
                  </span>
                )}
              </td>
              {/* Sentiment */}
              <td>
                {latestReply?.sentiment ? (
                  <span
                    className={`badge bg-${latestReply.sentiment.trim().toLowerCase() === 'positive'
                        ? 'success'
                        : latestReply.sentiment.trim().toLowerCase() === 'negative'
                          ? 'warning text-dark'
                          : 'info'
                      }`}
                  >
                    {latestReply.sentiment.trim()}
                  </span>
                ) : (
                  <span className="badge bg-secondary">-</span>
                )}
              </td>
              {/* Details Button */}
              <td className="text-center">
                <button
                  onClick={() => openPopup(emailGroup)}
                  className="expand-button badge bg-secondary cursor-pointer"
                >
                  Show details
                </button>
              </td>
              {/* AI Stop/Resume Buttons */}
              <td className="text-center">
                {isAiStopped ? (
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => {
                      const recipientEmail = primaryEmail.recipient_email;
                      const confirmed = window.confirm(
                        `Are you sure you want to resume sending responses for ${recipientEmail}?`
                      );
                      if (confirmed) {
                        handleResumeAiReply(recipientEmail, index);
                      }
                    }}
                     disabled={!!resumingAi[index]}
                  >
                    {!!resumingAi[index] ? 'Resuming...' : 'Resume'}
                  </button>
                ) : (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      const recipientEmail = primaryEmail.recipient_email;
                      const confirmed = window.confirm(
                        `Are you sure you want to stop sending responses for ${recipientEmail}?`
                      );
                      if (confirmed) {
                        handleStopAiReply(recipientEmail, index);
                      }
                    }}
                     disabled={!!stoppingAi[index] || !isReceived}
                  >
                    {!!stoppingAi[index] ? 'Stopping...' : 'STOP'}
                  </button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CampaignDataTable;