import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CampaignData, CampaignDataSaveEdit } from '../services/authFunctions';

export const fetchCampaignDetails = createAsyncThunk(
    'detailsTabSchedule/fetchCampaignDetails',
    async (campaignId, { rejectWithValue }) => {
        try {
            const campaignsData = await CampaignData(campaignId);
            if (Array.isArray(campaignsData)) {
                const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                if (filteredCampaign) {
                    return filteredCampaign;
                } else {
                    return rejectWithValue('Campaign not found');
                }
            } else {
                return rejectWithValue('Campaign data is not in the expected format.');
            }
        } catch (err) {
            return rejectWithValue(err.message || 'Failed to fetch campaign data');
        }
    }
);


export const saveCampaignDetails = createAsyncThunk(
    'detailsTabSchedule/saveCampaignDetails',
    async (payload, { rejectWithValue }) => {
       try {
            const response = await CampaignDataSaveEdit(payload);
             if (response && response.id) {
                return response
            } else {
                 return rejectWithValue('Unexpected API response');
            }
        } catch (err) {
            return rejectWithValue(err.message || "Error updating stage");
        }
    }
);

const initialState = {
    campaign: null,
    loading: false,
    error: null,
    stages: [],
};

const detailsTabScheduleSlice = createSlice({
    name: 'detailsTabSchedule',
    initialState,
    reducers: {
           setStages: (state, action) => {
            state.stages = action.payload;
        },
         updateStage: (state, action) => {
            const { index, field, value } = action.payload;
             if (state.stages && state.stages[index]) {
                  state.stages[index] = {
                    ...state.stages[index],
                    [field]: value,
                };
             }
           },
    },
    extraReducers: (builder) => {
        builder
             .addCase(fetchCampaignDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
             })
            .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.campaign = action.payload;
                state.stages = action.payload.stages.map(stage => ({
                    ...stage,
                    subject: stage.subject || '',
                    start_date: stage.start_date || '',
                    start_time: stage.start_time || '',
                }));
            })
            .addCase(fetchCampaignDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
             .addCase(saveCampaignDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveCampaignDetails.fulfilled, (state, action) => {
                state.loading = false;
                if (state.campaign) {
                     state.campaign.stages = state.stages
                }
            })
            .addCase(saveCampaignDetails.rejected, (state, action) => {
                 state.loading = false;
                 state.error = action.payload
            });
    },
});


export const { setStages, updateStage } = detailsTabScheduleSlice.actions;
export default detailsTabScheduleSlice.reducer;