import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams to get campaignId from the URL
import SearchBar from "../../../common/SearchBar.js";
import ContactFilter from "../../ContactBooks/ContactFilter.js";
import ContactData from "../../ContactBooks/ContactData.js";
import { CampaignData } from '../../../services/authFunctions'; // Assuming you have an API method to update stage
import CampaignButton from "./CampaignButton.js";
import CampaignDataImport from "./DataImport";

const DetailsTabLeads = () => {
    const { campaignId } = useParams(); // Get the campaignId from the URL params
    const [filters, setFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [campaign, setCampaign] = useState(null); // Store campaign data
    const [emailIds, setEmailIds] = useState([]); // State to store email ID

    const handleFiltersChange = (newFilters) => {
        setFilters(newFilters);
    };

    // Clear all filters (reset both filters and selectedFilters)
    const handleClearFilters = () => {
        setFilters({});
        setSelectedFilters({});
    };

    const handleSearchChange = (query) => {
        setSearchQuery(query);
    };

    // Fetch campaign data when campaignId changes
    const fetchCampaignData = async () => {
        if (!campaignId) {
            setError("Campaign ID is missing");
            setLoading(false);
            return;
        }

        try {
            const campaignsData = await CampaignData(campaignId);

            if (Array.isArray(campaignsData)) {
                const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                if (filteredCampaign) {
                    setCampaign(filteredCampaign);
                    setEmailIds(Array.isArray(filteredCampaign.email) ? filteredCampaign.email : [filteredCampaign.email || ""]);
                } else {
                    setError('Campaign not found');
                }
            } else {
                setError('Campaign data is not in the expected format.');
            }
        } catch (err) {
            console.error("Error fetching campaigns:", err);
            setError('Failed to fetch campaigns');
        } finally {
            setLoading(false);
        }
    };
    const downloadExcel = `${window.API_URL}/api/download-excel`;

    // Handle import success - trigger re-fetch of campaign data
    const handleImportSuccess = () => {
        setLoading(true); // Show loading while refetching
        fetchCampaignData();
    };

    useEffect(() => {
        setLoading(true); // Set loading true while fetching data
        fetchCampaignData();
    }, [campaignId]); // Run this effect when campaignId changes

    
    const handleDownloadClick = () => {
        const openURLnewtab = `${window.API_URL}/api/download-excel`;
        window.open(openURLnewtab, '_blank'); 
    };
    return (
        <div className="ff-inter h-100">
            <div className="row h-100 g-0">
                <div className="col-lg-2 col-md-3 h-100 pe-0 border-end">
                    <div className="border-bottom p-3">
                        <label className="fw-700 w-100 pb-3">Search</label>
                        <SearchBar onSearchChange={handleSearchChange} />
                    </div>

                    <div className="w-100">
                        <div className="d-flex px-3 py-2 border-bottom justify-content-between">
                            <label className="fw-700">Filter</label>
                            <button
                                className="border-0 bg-transparent fs-14 tc-979797"
                                onClick={handleClearFilters}
                            >
                                x Clear All
                            </button>
                        </div>
                        <div className="p-3">
                            <ContactFilter
                                onFiltersChange={handleFiltersChange}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={setSelectedFilters}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-10 col-md-9">
                    <div className="d-flex py-3 align-items-center px-3">
                        <h3 className="ff-inter h5 w-auto d-inline-block me-auto mb-0">Leads</h3>
                        {/* Import Button */}
                        <button type="button" class="btn border me-3 fs-14" onClick={handleDownloadClick}><img src="assets/img/svg/download.svg" alt="" /> Download </button>
                        <CampaignDataImport onImportSuccess={handleImportSuccess} />
                    </div>

                    {/* Show loading or error messages */}
                    {loading && <p>Loading campaign data...</p>}
                    {error && <p>Error: {error}</p>}

                    <div className="w-100">
                        {/* Only render ContactData if campaignId is fetched and data is available */}
                        {campaignId && !loading && !error && campaign && (
                            <ContactData
                                filters={filters}
                                searchQuery={searchQuery}
                                campaign_id={campaignId}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsTabLeads;
