import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ContactsData } from '../services/authFunctions'; 

// Async thunk for fetching data
export const fetchContactsData = createAsyncThunk(
    'detailsTab/fetchContacts',
    async (_, { rejectWithValue }) => {
        try {
            const contacts = await ContactsData();
            const trimmedContacts = contacts.map(contact => {
                const trimmedContact = {};
                Object.keys(contact).forEach(key => {
                    if (typeof contact[key] === 'string') {
                        trimmedContact[key] = contact[key].trim();
                    } else {
                        trimmedContact[key] = contact[key];
                    }
                });
                return trimmedContact;
            });
            return trimmedContacts;
        } catch (error) {
             return rejectWithValue(error.message);

        }
    }
);
const initialState = {
    data: [],
    loading: false,
    error: null,
    activeTab: "analytics",
};

const detailsTabSlice = createSlice({
    name: 'detailsTab',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
         resetState : (state)=>{
            state.data = [];
            state.loading = false;
            state.error = null;
            state.activeTab = "analytics"
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContactsData.pending, (state) => {
                state.loading = true;
                 state.error = null;
            })
            .addCase(fetchContactsData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                  state.error = null;
            })
            .addCase(fetchContactsData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setActiveTab, resetState } = detailsTabSlice.actions;
export default detailsTabSlice.reducer;