import React, { useState, useEffect } from 'react';

import { gsuiteDataShowdata } from '../../../services/authFunctions';

const EmailSettings = () => {
   

    const [dailyLimit, setDailyLimit] = useState(25000);
    const [stopOnReply, setStopOnReply] = useState(true);
    const [openTracking, setOpenTracking] = useState(true);
    const [linkTracking, setLinkTracking] = useState(true);
    const [textOnly, setTextOnly] = useState(false);

    /*// Hide advanced options*/
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [owner, setOwner] = useState("Admin");

    const toggleAdvanced = () => {
        setShowAdvanced(!showAdvanced);
    };
    /*Sending pattern*/
    const [minTime, setMinTime] = useState('00');
    const [randomTime, setRandomTime] = useState('00');
    const [maxLeads, setMaxLeads] = useState('No limit');
    const [textOnlyPrioritize, setTextOnlyPrioritize] = useState(false);
    const [winningMetric, setWinningMetric] = useState('');
    const [providerMatching, setProviderMatching] = useState(false);
    const [companyReplyStop, setCompanyReplyStop] = useState(false);
    const [autoReplyStop, setAutoReplyStop] = useState(false);
    const [unsubscribeLink, setUnsubscribeLink] = useState(false);

    const [riskyEmails, setRiskyEmails] = useState(false);
    const [disableBounceProtect, setDisableBounceProtect] = useState(false);

    const [ccRecipients, setCcRecipients] = useState('');
    const [bccRecipients, setBccRecipients] = useState('');
    /*// Button save */
    const handleSave = () => {
        // Handle save logic here
        console.log('Settings saved');
    };

    const handleLaunch = () => {
        // Handle launch logic here
        console.log('Campaign launched');
    };

    const [emailAccounts, setEmailAccounts] = useState([]);
    useEffect(() => {
        const fetchEmailAccounts = async () => {
            const data = await gsuiteDataShowdata(); // Fetch the data from API
            console.log("Fetched data:", data); // Log the response to see its structure

            // Check if GsuiteAccount exists and is an array
            if (data && data.GsuiteAccount && Array.isArray(data.GsuiteAccount)) {
                setEmailAccounts(data.GsuiteAccount); // Set the email accounts if the array is present
            } else {
                console.error("Data fetched is not in expected format:", data);
                setEmailAccounts([]); // Fallback to empty array if data is not in expected format
            }
        };

        fetchEmailAccounts();
    }, []);
    return (

        <div className="container mt-4">

            <div className="card mb-3">
                <div className="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <h6 className="card-title">Accounts to use</h6>
                            <small className="text-muted">Select one or more accounts to send emails from</small>
                        </div>
                        <div class="col-md-4">
                            {/*<div className="mt-2">*/}
                            <select className="form-select" multiple>
                                {emailAccounts
                                    .filter(account => account.is_active === 1) // Only show active accounts
                                    .map(account => (
                                        <option key={account.id} value={account.email}>
                                            {account.email}
                                        </option>
                                    ))}
                            </select>
                            {/*<small className="text-muted">+208 more</small>*/}
                        </div>

                        <div class="col-md-4">
                            <span className="text-danger ms-2">6 inactive</span>
                        </div>
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h6 className="card-title">Stop sending emails on reply</h6>
                            <small className="text-muted">Stop sending emails to a lead if a response has been received</small>
                        </div>
                        <div class="col-md-6">
                            {/* <div className="mt-2">*/}
                            <div className="btn-group">
                                <button
                                    className={`btn ${stopOnReply ? 'btn-success' : 'btn-outline-secondary'}`}
                                    onClick={() => setStopOnReply(true)}
                                >
                                    Enable
                                </button>
                                <button
                                    className={`btn ${!stopOnReply ? 'btn-secondary' : 'btn-outline-secondary'}`}
                                    onClick={() => setStopOnReply(false)}
                                >
                                    Disable
                                </button>
                            </div>
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <h6 className="card-title">Open Tracking</h6>
                            <small className="text-muted">Track email opens</small>
                        </div>
                        <div class="col-md-4">
                            <div className="mt-2 d-flex align-items-center">
                                <div className="form-check me-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={linkTracking}
                                        onChange={(e) => setLinkTracking(e.target.checked)}
                                        id="linkTracking"
                                    />
                                    <label className="form-check-label" htmlFor="linkTracking">
                                        link tracking
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="btn-group">
                                <button
                                    className={`btn ${openTracking ? 'btn-success' : 'btn-outline-secondary'}`}
                                    onClick={() => setOpenTracking(true)}
                                >
                                    Enable
                                </button>
                                <button
                                    className={`btn ${!openTracking ? 'btn-secondary' : 'btn-outline-secondary'}`}
                                    onClick={() => setOpenTracking(false)}
                                >
                                    Disable
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div className="d-flex align-items-center">
                                <h6 className="card-title mb-0">Delivery Optimization</h6>
                                <span className="badge bg-success ms-2">Recommended</span>
                            </div>
                            <small className="text-muted d-block">Disables open tracking</small>
                        </div>

                        <div class="col-md-6">
                            <div className="mt-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={textOnly}
                                        onChange={(e) => setTextOnly(e.target.checked)}
                                        id="textOnly"
                                    />
                                    <label className="form-check-label" htmlFor="textOnly">
                                        Send emails as text-only (no HTML)
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h6 className="card-title">Daily Limit</h6>
                            <small className="text-muted">Max number of emails to send per day for this campaign</small>
                        </div>

                        <div class="col-md-6">
                            <div className="mt-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={dailyLimit}
                                    onChange={(e) => setDailyLimit(e.target.value)}
                                    style={{ maxWidth: '200px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*// Show Advanced Options*/}
            <div>
                <button
                    className="btn btn-link d-flex align-items-center mx-auto mb-4"
                    onClick={toggleAdvanced}
                >
                    {showAdvanced ? 'Hide' : 'Show'} advanced options
                    <i className={`bi bi-chevron-${showAdvanced ? 'up' : 'down'} ms-2`}></i>
                </button>
            </div>

            {showAdvanced && (
                <div className="advanced-settings">
                    <h6>CRM</h6>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">Owner</h6>
                                    <small className="text-muted">Select the owner of this campaign</small>
                                </div>
                                <div class="col-md-6">
                                    <select
                                        className="form-select"
                                        value={owner}
                                        onChange={(e) => setOwner(e.target.value)}
                                    >
                                        <option value="Admin">Admin</option>
                                        {/* Add other owner options */}
                                    </select>

                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="card-title">Custom Tags</h6>
                    <small className="text-muted">Tags are used to group your campaigns</small>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <select className="form-select mt-2">
                                        <option value="">Select tags...</option>
                                        {/* Add tag options */}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="card-title">Sending pattern</h6>
                    <small className="text-muted">Specify how you want your emails to go</small>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div className="row align-items-center mt-3">
                                    <div class="col-md-4">
                                        <h6 className="card-title">Time gap between emails</h6>
                                    </div>

                                    <div className="col-md-4">
                                        <small className="text-muted">Minimum time</small>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="00"
                                                value={minTime}
                                                onChange={(e) => setMinTime(e.target.value)}
                                            />
                                            <span className="input-group-text">minutes</span>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <small className="text-muted">Random additional time</small>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="00"
                                                value={randomTime}
                                                onChange={(e) => setRandomTime(e.target.value)}
                                            />
                                            <span className="input-group-text">minutes</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">Max new leads</h6>
                                </div>
                                <div class="col-md-6">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={maxLeads}
                                            onChange={(e) => setMaxLeads(e.target.value)}
                                        />
                                        <span className="input-group-text">per day</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <h6 className="card-title">Prioritize new leads</h6>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div className="mt-2">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={textOnlyPrioritize}
                                                onChange={(e) => setTextOnlyPrioritize(e.target.checked)}
                                                id="textOnly"
                                            />
                                            <label className="form-check-label" htmlFor="textOnly">
                                                Send emails as text-only (no HTML)
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="d-flex align-items-center mb-2">
                                        <h6 className="card-title">Auto optimize A/2 testing</h6>
                                        <span className="badge bg-success ms-2">New</span>
                                    </div>
                                    <small className="text-muted d-block mb-3">
                                        When using A/2 testing, the Instantly algorithm will automatically select the best performing variant after a certain number of emails have been sent.
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <select
                                        className="form-select"
                                        value={winningMetric}
                                        onChange={(e) => setWinningMetric(e.target.value)}
                                    >
                                        <option value="">Choose winning metric</option>
                                        {/* Add metric options */}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">Provider Matching</h6>
                                    <small className="text-muted d-block mb-3">
                                        Matches your lead's email provider with your mailbox provider for boosted deliverability. (Outlook to Outlook, Google to Google, etc.)
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="providerMatching"
                                            checked={providerMatching}
                                            onChange={(e) => setProviderMatching(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="providerMatching">
                                            Enable provider matching for deliverability boost
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">Stop campaign for company on reply</h6>
                                    <small className="text-muted d-block mb-3">
                                        Stops the campaign additionally for all leads from a company if a reply is received from any of them
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="companyReplyStop"
                                            checked={companyReplyStop}
                                            onChange={(e) => setCompanyReplyStop(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="companyReplyStop">
                                            Enable company reply stop
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">Stop sending emails on Auto-reply</h6>
                                    <small className="text-muted">
                                        Stop sending emails to a lead if an automatic response has been received, for example for out-of-office replies.
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="autoReplyStop"
                                            checked={autoReplyStop}
                                            onChange={(e) => setAutoReplyStop(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="autoReplyStop">
                                            Stop on auto-reply
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">Insert unsubscribe link header</h6>
                                    <small className="text-muted">
                                        Automatically adds an unsubscribe link to email headers for one-click unsubscription by supported email providers
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="unsubscribeLink"
                                            checked={unsubscribeLink}
                                            onChange={(e) => setUnsubscribeLink(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="unsubscribeLink">
                                            Insert unsubscribe link header
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">Allow risky emails</h6>
                                    <small className="text-muted d-block mb-3">
                                        When using verification, allow emails that are marked as risky to be contacted, or disable BounceProtect to allow known risky emails to be contacted.
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="riskyEmails"
                                                checked={riskyEmails}
                                                onChange={(e) => setRiskyEmails(e.target.checked)}
                                            />
                                            <label className="form-check-label" htmlFor="riskyEmails">
                                                Enable risky emails
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="bounceProtect"
                                                checked={disableBounceProtect}
                                                onChange={(e) => setDisableBounceProtect(e.target.checked)}
                                            />
                                            <label className="form-check-label" htmlFor="bounceProtect">
                                                Disable BounceProtect
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="card-title">CC and BCC</h6>
                    <small className="text-muted">
                        Add cc and bcc recipients to your emails.
                    </small>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">CC</h6>
                                    <small className="text-muted d-block mb-2">
                                        Send a copy of the email to the addresses listed in the field
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={ccRecipients}
                                        onChange={(e) => setCcRecipients(e.target.value)}
                                        placeholder="Separate emails with a comma"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 className="card-title">BCC</h6>
                                    <small className="text-muted d-block mb-2">
                                        Send a copy of the email to certain recipients without the other recipients knowing about it
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={bccRecipients}
                                        onChange={(e) => setBccRecipients(e.target.value)}
                                        placeholder="Separate emails with a comma"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            )}
            <div className="d-flex justify-content-center gap-3">
                <button
                    className="btn btn-outline-secondary"
                    onClick={handleSave}
                >
                    <i className="bi bi-save me-2"></i>
                    Save
                </button>
                <button
                    className="btn btn-primary d-flex align-items-center"
                    onClick={handleLaunch}
                >
                    <i className="bi bi-rocket-takeoff me-2"></i>
                    Launch
                </button>
            </div>
        </div >

    );

};

export default EmailSettings;