import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CampaignData, ScheduleTaskData } from '../services/authFunctions';

const initialState = {
    stages: [],
    loading: false,
    error: null,
    showEmails: false,
    storedEmails: [],
    emailDetails: {},
    sending: false,
    isRunning: false,
    hasRun: false,
};

// Async thunk to fetch campaign details (just stages)
export const fetchCampaignDetails = createAsyncThunk(
  'campaignButton/fetchCampaignDetails',
  async (campaignId) => {
    try {
      const campaignsData = await CampaignData(campaignId);
      if (Array.isArray(campaignsData)) {
        const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
        if (filteredCampaign) {
          return filteredCampaign.stages || [];
        } else {
          throw new Error('Campaign not found');
        }
      } else {
        throw new Error('Campaign data is not in the expected format.');
      }
    } catch (err) {
      throw new Error(`Failed to fetch campaign details: ${err.message}`);
    }
  }
);
// Async thunk to schedule the campaign tasks
export const scheduleCampaignTasks = createAsyncThunk(
    'campaignButton/scheduleCampaignTasks',
    async (payload) => {
      try {
        const response = await ScheduleTaskData(payload);
        return response; // You might want to return some data here
      } catch (error) {
        throw new Error(`Failed to schedule campaign tasks: ${error.message}`);
      }
    }
  );
const campaignButtonSlice = createSlice({
    name: 'campaignButton',
    initialState,
    reducers: {
        setShowEmails: (state, action) => {
            state.showEmails = action.payload;
        },
        setStoredEmails: (state, action) => {
          state.storedEmails = action.payload;
        },
         setEmailDetails: (state, action) => {
            state.emailDetails = {
            ...state.emailDetails,
            ...action.payload,
            };
         },
         setSendingStatus: (state, action) => {
            state.sending = action.payload;
         },
         setIsRunningStatus: (state, action) => {
            state.isRunning = action.payload
         },
         setHasRunStatus: (state, action) => {
             state.hasRun = action.payload;
          },
        // Reset the state
        resetState: (state) => {
          state.stages = [];
          state.loading = false;
          state.error = null;
          state.showEmails = false;
          state.storedEmails = [];
          state.emailDetails = {};
          state.sending = false;
          state.isRunning = false;
          state.hasRun = false;
         }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaignDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.stages = action.payload;
            })
            .addCase(fetchCampaignDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.stages = [];
            })
            .addCase(scheduleCampaignTasks.pending, (state) => {
              state.sending = true;
              state.isRunning = true;
            })
            .addCase(scheduleCampaignTasks.fulfilled, (state) => {
               state.sending = false;
               state.isRunning = false;
               state.hasRun = true;
            })
            .addCase(scheduleCampaignTasks.rejected, (state, action) => {
               state.sending = false;
              state.isRunning = false;
              state.error = action.error.message
            });
    },
});

// Selectors
export const selectStages = (state) => state.campaignButton.stages;
export const selectCampaignButtonLoading = (state) => state.campaignButton.loading;
export const selectCampaignButtonError = (state) => state.campaignButton.error;
export const selectShowEmails = (state) => state.campaignButton.showEmails;
export const selectStoredEmails = (state) => state.campaignButton.storedEmails;
export const selectEmailDetails = (state) => state.campaignButton.emailDetails;
export const selectSendingStatus = (state) => state.campaignButton.sending;
export const selectIsRunningStatus = (state) => state.campaignButton.isRunning;
export const selectHasRunStatus = (state) => state.campaignButton.hasRun;

export const {
    setShowEmails,
    setStoredEmails,
    setEmailDetails,
    setSendingStatus,
    setIsRunningStatus,
    setHasRunStatus,
    resetState,
} = campaignButtonSlice.actions;

export default campaignButtonSlice.reducer;