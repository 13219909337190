import React, { useEffect, useState, useMemo } from 'react';
import { CampaignData, ScheduleTaskData } from '../../../services/authFunctions';

const CampaignButtonStart = ({ emailIds, campaignId }) => {
    const [stages, setStages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showEmails, setShowEmails] = useState(false);
    const [storedEmails, setStoredEmails] = useState([]);
    const [emailDetails, setEmailDetails] = useState({});
    const [sending, setSending] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [hasRun, setHasRun] = useState(false);


    const isLeads = useMemo(() => {
        if (!Array.isArray(emailIds) || !campaignId) {
            return false;
        }
        const filtered = emailIds.filter(email => email.campaign_id === parseInt(campaignId));
        return filtered.length > 0;
    }, [emailIds, campaignId]);

    useEffect(() => {
        console.log('Props changed:', emailIds);
    }, [emailIds])


    useEffect(() => {
        console.log("cmp id", campaignId);
        console.log("isLeads", isLeads);
    }, [campaignId, isLeads]);

    // Fetch campaign details (just stages)
    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const campaignsData = await CampaignData(campaignId);
                if (Array.isArray(campaignsData)) {
                    const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                    if (filteredCampaign) {
                        setStages(filteredCampaign.stages || []); // Set stages data
                    } else {
                        setError('Campaign not found');
                    }
                } else {
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaigns:", err);
                setError('Failed to fetch campaigns');
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchCampaign();
        } else {
            setError('Campaign ID is missing');
            setLoading(false);
        }
    }, [campaignId]);

    // Handle change in email details for each stage
    const handleChange = (e, stageId) => {
        const { name, value } = e.target;

        // Update the email details for the specific stage
        setEmailDetails(prev => ({
            ...prev,
            [stageId]: {
                ...prev[stageId],
                [name]: value,
            },
        }));
    };

    // Update current time periodically
    useEffect(() => {
        const intervalId = setInterval(() => setCurrentTime(new Date()), 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Toggle email visibility and fetch emails from localStorage
    const toggleEmailVisibility = () => {
        setShowEmails((prev) => !prev);
        if (!showEmails && !storedEmails.length) {
            const emailData = localStorage.getItem('emailIds');
            if (emailData) {
                setStoredEmails(JSON.parse(emailData));
            }
        }
    };

    const handleRunClick = () => {
        if (!isLeads) {
            alert("You don't have leads to run the campaign.");
        } else {
             sendEmails();
        }
    }


     const sendEmails = async () => {
       if (hasRun) {
        return;
      }

        const payload = {
            stage: stages.map((stage) => ({
                stage: stage.stage_number,
                campaign_id: stage.campaign_id,
                date: stage.start_date,
               start_time: stage.start_time,
            })),
        };


        setIsRunning(true);
        setSending(true);

        // Schedule the tasks for each stage
        try {
            const response = await ScheduleTaskData(payload);
             alert("Campaign emails sent successfully");
        } catch (error) {
          alert('The stages for this campaign have already been sent.');
        }
         finally {
          setSending(false);
          setIsRunning(false);
          setHasRun(true);

        }
    }



    // Fetch stored emails on component mount
    useEffect(() => {
        const emailData = localStorage.getItem('emailIds');
        if (emailData) {
            setStoredEmails(JSON.parse(emailData));
        }
    }, []);

    if (loading) {
        return <div>Loading stages...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            {stages.length > 0 ? (
                stages.map((stage) => {
                    const stageStartDateTime = new Date(`${stage.start_date} ${stage.start_time}`);
                    const isPast = currentTime >= stageStartDateTime;
                    const isSent = localStorage.getItem(`campaign_${stage.campaign_id}_sent`);

                    return (
                        <div key={stage.id}>
                            {stage.stage_number === 1 && (
                                <div>
                                    <input
                                        type="text"
                                        name="subject"
                                        value={emailDetails[stage.id]?.subject || stage.subject}
                                        onChange={(e) => handleChange(e, stage.id)}
                                        placeholder="Subject"
                                        hidden
                                    />
                                    <textarea
                                        name="message"
                                        value={emailDetails[stage.id]?.message || stage.description}
                                        onChange={(e) => handleChange(e, stage.id)}
                                        placeholder="Message"
                                        hidden
                                    />
                                    <button
                                        className="btn btn-primary mb-3"
                                        onClick={handleRunClick}
                                        disabled={sending || hasRun}
                                    >
                                         Run Campaign
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                })
            ) : (
                <p>No stages available</p>
            )}
        </div>
    );
};

export default CampaignButtonStart;
