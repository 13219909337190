import React from 'react';
import DeleteBtn from '../../../assets/img/DeleteBtn.png';

const StageList = ({ campaign, selectedStage, handleStageClick, handleAddStage, handleDelete }) => {
    return (
        <div className="bg-F5F8FF p-3 rounded-3">
            {campaign && campaign.stages && campaign.stages.length > 0 ? (
                campaign.stages.map((stage) => (
                    <div key={stage.id} className="card mb-3">
                        <a
                            href="javascript:void(0)"
                            className={`card-body d-flex justify-content-between align-items-center text-dark text-decoration-none ${selectedStage?.id === stage.id ? "bg-light" : ""}`}
                            onClick={() => handleStageClick(stage)}
                        >
                            <span>
                                Stage Number {stage.stage_number}
                            </span>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(stage.id);
                                }}
                                className="btn btn-sm"
                                title="Delete Stage"
                            >
                                 <img src={DeleteBtn} alt="Delete" />
                            </button>
                        </a>
                    </div>
                ))
            ) : (
                <p>No stages available</p>
            )}

            <button
                onClick={handleAddStage}
                className="btn border mt-3 center-block w-100"
            >
                Add Stage
            </button>
        </div>
    );
};

export default StageList;