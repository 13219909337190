import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPage: 1,
    itemsPerPage: 10,
    selectedItems: [],
    popoverRow: null,
};

const dataTableSlice = createSlice({
    name: 'dataTable',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.itemsPerPage = action.payload;
           state.currentPage = 1; // Reset to first page when items per page is changed
        },
        setSelectedItems: (state, action) => {
            state.selectedItems = action.payload;
        },
        setPopoverRow: (state, action) => {
            state.popoverRow = action.payload;
        },
        toggleSelectItem: (state, action) => {
            const id = action.payload;
             if (state.selectedItems.includes(id)) {
                state.selectedItems = state.selectedItems.filter(item => item !== id);
              } else {
                  state.selectedItems.push(id)
              }
        }
    },
});

export const { setCurrentPage, setItemsPerPage, setSelectedItems, setPopoverRow, toggleSelectItem } = dataTableSlice.actions;

export default dataTableSlice.reducer;