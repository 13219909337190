import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCampaignData,
    setSelectedStage,
    setSubject,
    setDescription,
    saveCampaignData,
    resetState,
    addStage,
    updateCampaignState
} from '../../../features/detailsTabSequencesSlice';
import StageList from './StageList';
import StageEditor from './StageEditor';

const DetailsTabSequences = () => {
    const { campaignId } = useParams();
    const dispatch = useDispatch();
    const { campaign, loading, error, selectedStage, subject, description } = useSelector(
        (state) => state.detailsTabSequences
    );

    useEffect(() => {
        if (campaignId) {
            dispatch(fetchCampaignData(campaignId));
        }
        return () => {
            dispatch(resetState());
        };
    }, [dispatch, campaignId]);

    const handleStageClick = (stage) => {
        dispatch(setSelectedStage(stage));
    };

    const handleSave = async () => {
        if (selectedStage && selectedStage.id) {
            if (!subject.trim() || !description.trim()) {
                showAlert(`Subject and Message are required for stage ${selectedStage.stage_number}.`, "warning");
                return;
            }

            try {
                const updatedStage = {
                    ...selectedStage,
                    subject,
                    description
                };
                const updatedStages = campaign.stages.map(stage =>
                    stage.id === selectedStage.id ? updatedStage : stage
                );
                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    return date.toISOString().slice(0, 19).replace('T', ' ');
                };

                if (!campaign || !campaign.id) {
                    alert("Campaign ID is missing. Cannot update.");
                    return;
                }
                const requestData = {
                    user_id: "1",
                    id: campaign.id,
                    name: campaign.name,
                    status: campaign.status,
                    scheduled_at: campaign.scheduled_at,
                    start_date: formatDate(campaign.start_date),
                    end_date: formatDate(campaign.end_date),
                    stages: updatedStages
                };
                dispatch(saveCampaignData(requestData)).then(() => {
                    showAlert("Stage updated successfully!", "success");
                    dispatch(updateCampaignState({ ...campaign, stages: updatedStages }))
                }).catch((err) => {
                    showAlert(err, "danger")
                });


            } catch (err) {
                console.error("Error updating stage:", err);
                showAlert("Error updating stage", "danger");
            }
        } else {
            showAlert("No valid stage selected to update.", "danger");
        }
    };

    const showAlert = (message, type) => {
        const alertContainer = document.getElementById("alert-container");
        const alertDiv = document.createElement("div");
        alertDiv.classList.add("alert", `alert-${type}`, "alert-dismissible", "fade", "show", "fixed-top");
        alertDiv.setAttribute("role", "alert");
        alertDiv.innerHTML = `
            ${message}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        `;
        alertContainer.appendChild(alertDiv);
        setTimeout(() => {
            alertDiv.classList.remove("show");
            alertDiv.classList.add("fade");
            setTimeout(() => alertDiv.remove(), 500);
        }, 3000);
    };


    if (loading) {
        return <p>Loading campaign data...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const handleAddStage = () => {
        if (campaign && campaign.stages && campaign.stages.length > 0) {
            const lastStage = campaign.stages[campaign.stages.length - 1];
            if (!lastStage.subject || !lastStage.description) {
                showAlert(`Please add subject and message to stage ${lastStage.stage_number} to create a new stage`, "warning")
                return;
            }
        }
        const highestStageId = campaign ? campaign.stages.reduce((max, stage) => Math.max(max, stage.id), 0) : 0;
        const newStage = {
            id: highestStageId + 1,
            stage_number: (campaign?.stages?.length || 0) + 1,
            subject: '',
            description: ''
        };
        dispatch(addStage(newStage));
    };

    const handleDelete = async (stageId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this stage?");
        if (!confirmDelete) return;

        try {
            const updatedStages = campaign.stages.filter(stage => stage.id !== stageId);

            const formatDate = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().slice(0, 19).replace('T', ' ');
            };

            const requestData = {
                user_id: "1",
                id: campaign.id,
                name: campaign.name,
                status: campaign.status,
                scheduled_at: campaign.scheduled_at,
                start_date: formatDate(campaign.start_date),
                end_date: formatDate(campaign.end_date),
                stages: updatedStages
            };

            dispatch(saveCampaignData(requestData)).then(() => {
                showAlert("Stage deleted successfully!", "success");
                dispatch(updateCampaignState({ ...campaign, stages: updatedStages }))
            }).catch((err) => {
                showAlert(err, "danger")
            })

        } catch (err) {
            console.error("Error deleting stage:", err);
            showAlert("Error deleting stage", "danger");
        }
    };
    const handleDescriptionChange = (value) => {
        dispatch(setDescription(value));
    };
    const handleSubjectChange = (e) => {
        dispatch(setSubject(e.target.value));
    };

    return (
        <div className="ff-inter h-100">
            <div id="alert-container"></div>
            <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                    <StageList
                        campaign={campaign}
                        selectedStage={selectedStage}
                        handleStageClick={handleStageClick}
                        handleAddStage={handleAddStage}
                        handleDelete={handleDelete}
                    />
                </div>
                <div className="col-12 col-md-8 col-xl-9">
                    <StageEditor
                        selectedStage={selectedStage}
                        subject={subject}
                        description={description}
                        handleSubjectChange={handleSubjectChange}
                        handleDescriptionChange={handleDescriptionChange}
                        handleSave={handleSave}
                    />
                </div>
            </div>
        </div>
    );
};

export default DetailsTabSequences;